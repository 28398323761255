.containerM {
  list-style: none;
  margin: 0;
  padding: 0;
}
.itemM {
  background: white;
  padding: 5px;
  width: 210px;

  margin: 4px;
  border-style: solid;
  border-width: 1px;

  color: black;

  font-size: 12px;
  text-align: left;
}

.itemMVurdert {
  background: rgb(182, 211, 241);
  padding: 5px;
  width: 210px;

  margin: 4px;
  border-style: solid;
  border-color: dodgerblue;
  border-width: 1px;

  color: black;

  font-size: 12px;
  font-style: italic;
  text-align: left;
}

.itemMForbud {
  background: gray;
  padding: 5px;
  width: 210px;

  margin: 4px;
  border-style: solid;
  border-color: red;
  border-width: 2px;

  color: white;

  font-size: 12px;
  text-align: left;
}

.itemMUten {
  background: white;
  padding: 5px;
  width: 210px;

  margin: 4px;
  border-style: solid;
  border-width: 1px;

  color: black;

  font-size: 12px;
  font-style: italic;
  text-align: left;
}

/*float layout*/
.floatM {
  max-width: 1200px;
  margin: 0 auto;
}
.floatM:after {
  content: '.';
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
.floatM-item {
  float: left;
}

.sk-pagination-navigation {
  width: 220px;
  height: 30px !important;
  margin-top: 0;
  margin-left: 0;
  padding: 0;
}

.sk-reset-filters {
  padding-right: 0 !important;
}

#outer
{
    width:100%;
    text-align: left;
}

.inner
{
    display: inline-block;
    margin-right: 8px;
}

.sk-filter-group__remove-action {
  color: darkred;
  margin-left: 6px;
}

.sk-filter-group-items__value {
  font-size: 12px;
  font-weight: normal;
}

.sk-filter-group-items__title {
  font-size: 12px;
  font-weight: normal;
}

.sk-filter-group-items__list {
  font-size: 12px;
  font-weight: normal;
}

.sk-search-box {
  height: 29.3px;
}

.sk-search-box__icon {
  margin-top: 4px;
}

