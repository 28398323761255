.btn100 {
  width: 100% !important;
  min-width: 180px;
}

.fr-box.fr-basic .fr-element {
  /*  padding: 0 !important;  */
  padding-left: 0 !important;
}

.norkartGreen {
  background-color: #1E7400;
  color: white;
}

.norkartGreen:hover {
  background-color: rgb(216, 231, 209) ;
  color: #333;
  border: 1.5px solid #1E7400 !important;
}

.zindex {
  z-index: 8000;
}

.scrollmenu {
  background-color: transparent;
  overflow: auto;
  white-space: nowrap;
  
}

.scrollmenuItem {
  background-color: lightgray;
}

.scrollmenu div {
  display: inline-block;
  color: #333;
  text-align: center;
  padding: 0px;
  text-decoration: none;
}

.scrollmenu div:hover {
  background-color: white;
  border: 2px solid green;
}

.nav-tabs {
  background-color: #f6f6f6 !important;
}

.bfAvvik {
  vertical-align: text-top !important;
  display: inline-block;
  width: 300px;
  min-height: 130px;
  max-height: 130px;
  background-color: transparent;
  color: #333;
  /*   font-size: 32px; */
  border-top: 3px solid green;
  border-left: 1.5px solid lightgray;
  border-right: 1.5px solid lightgray;
  border-bottom: 1.5px solid lightgray;
  border-radius: 0px;
  cursor: pointer;
  padding: 4px;
  margin: 4px;
}

.avvikvalgt {
  background-color: lightgray;
  /*  border: 1.5px solid green; */
}

.bfAvvik:hover {
  background-color: lightgray;
  /* border: 1.5px solid green; */
}

.bfBlaa {
  background-color: rgb(135, 168, 224) !important; 
}

.bfAvvikAapen {
  vertical-align: text-top !important;
  display: inline-block;
  width: 300px;
  min-height: 130px;
  max-height: 130px;
  letter-spacing: 0.5px;
  background-color:  white;
  color: #333;
  border: 1px solid gray;
  border-radius: 0px;
  cursor: pointer;
  padding: 4px;
  margin: 4px;
}

.bfAvvikAapen2 {
  vertical-align: text-top !important;
  display: inline-block;
  width: 250px;
  max-width: 250px;
  min-height: 150px;
  max-height: 150px;
  letter-spacing: 0.5px;
  background-color:  white;
  color: #333;
  /*   font-size: 32px; */
  border: 1px solid lightgray;
  border-radius: 0px;
  cursor: pointer;
  padding: 6px;
  margin: 4px;
}

.bfAvvikAapen:hover {
  border: 2px solid #1E7400;
}

.bfAvvikAapen2:hover {
  border: 2px solid #1E7400;
  background-color: lightgray !important;
}

.bfAvvikAapen2Valgt {
  vertical-align: text-top !important;
  display: inline-block;
  width: 250px;
  max-width: 250px;
  min-height: 150px;
  max-height: 150px;
  letter-spacing: 0.5px;
  background-color: white;
  border: 3px solid #1E7400;
  color: #333;
  border-radius: 0px;
  cursor: pointer;
  padding: 6px;
  margin: 4px;
}

.avvikvalgtaapen {
  background-color: lightgray;
  /* border: 1.5px solid orange; */
}

.bfAvvikAapen:hover {
  border: 2px solid #1E7400;
}

.text-top-extra {
  vertical-align: text-top !important;
}

#parentX {
  display: flex;
}

#dokX {
  width: 780px;
  margin-Right: 20px;
  vertical-align: text-top !important;
}

#komX {
  width: 540px;
  vertical-align: text-top !important;
}

.kontaktScroll {
  overflow-y: scroll;
  height: auto;
 /*  max-height: 580px; */
  max-height: calc(100vh - 500px);
}

.spanWrap {
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.typeWrap {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  }

  .bildeButton {
    border: 1px solid #1E7400;
    background-color: white;
  }

  .bildeButton:hover {
    border: 3px solid #1E7400;
    background-color: lightgray;
  }

  .badge {
    padding: 1px 9px 2px;
    font-size: 12.025px;
    font-weight: bold;
    white-space: nowrap;
    color: #ffffff;
    background-color: #999999;
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    border-radius: 9px;
  }
  .badge:hover {
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;
  }
  .badge-error {
    background-color: #b94a48;
  }
  .badge-error:hover {
    background-color: #953b39;
  }
  .badge-warning {
    background-color: #f89406;
  }
  .badge-warning:hover {
    background-color: #c67605;
  }
  .badge-success {
    background-color: #468847;
  }
  .badge-success:hover {
    background-color: #356635;
  }
  .badge-info {
    background-color: #3a87ad;
  }
  .badge-info:hover {
    background-color: #2d6987;
  }
  .badge-inverse {
    background-color: #333333;
  }
  .badge-inverse:hover {
    background-color: #1a1a1a;
  }

