.panel-heading.collapsed .fa-chevron-up,
.panel-heading .fa-chevron-down {
  display: none;
}

.panel-heading.collapsed .fa-chevron-down,
.panel-heading .fa-chevron-up {
  display: inline-block;
}

i.fa {
  cursor: pointer;
  margin-right: 5px;
}

.collapsed ~ .panel-body {
  padding: 0;
}