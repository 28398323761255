.icon-active {
  background-color: #51a026 !important;
}

.icon-ruler {
  cursor: pointer !important;
  background-image: url('./maale.png') !important;
  height: 40px !important;
  width: 40px !important;
  background-color: lightgray;
}

.ruler-map {
  cursor: crosshair !important;
}

.total-popup {
  width: auto !important;
  height: auto !important;
  padding-left: 15px;
  margin-top: -10px !important;
  background-color: transparent;
}

.total-popup-content {
  padding: 1px 7px;
  background-color: #4d90fe;
  border-radius: 8px;
  color: white;
  font-weight: bold;
  white-space: nowrap;
  text-align: center;
}

.total-popup-content .poly-close {
  display: none;
}

.total-popup-content .poly-close:hover {
  opacity: 0.7;
}

.total-popup-content:hover .poly-close {
  display: inline;
  margin-left: 10px;
  position: relative;
  cursor: pointer;
}

.total-popup-content svg {
  width: 15px;
  height: 10px;
  position: relative !important;
  top: 0px !important;
  left: 5px !important;
}

.total-popup-content svg path {
  stroke: white;
  fill: transparent;
  stroke-linecap: round;
  stroke-width: 7;
}

.total-popup-content svg:hover {
  opacity: 0.7;
}

.total-popup-content svg:active {
  opacity: 0.3;
}

.total-popup-label {
  padding: 0px;
  padding-top: 10px;
  background-color: transparent;
  text-shadow: 1px 1px 0px rgba(255, 255, 255, 1);
  color: #4d90fe;
  font-weight: bold;
  font-size: 10px;
  white-space: nowrap;
}

.node-label {
  top: -25px !important;
}

.azimut {
  color: blue;
  text-shadow: 1px 1px 0px white;
  font-size: 13px;
  font-weight: normal;
}

.azimut-final {
  text-shadow: none;
  font-weight: bold;
}
