.Collapsible {
    background-color: transparent;
    min-Width: 776px;
    max-Width: 776px;
 }
  .Collapsible__contentInner {
    background-color: white;
    max-height: 600px;;
    padding: 10px;
    border: 1px solid lightGrey;
    overflow-y: scroll;
    border-bottom: 3px solid gray;
 }
  .Collapsible__contentInner p {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 20px;
 }
  .Collapsible__contentInner p:last-child {
    margin-bottom: 0;
 }
  .Collapsible__trigger {
    display: block;
    font-weight: 400;
    text-decoration: none;
    color: #333;
    position: relative;
    border: 1px solid lightGrey;
    padding: 10px;
    background: white;
    color: #333;
    margin-bottom: 4px;
    margin-top: 4px;
 }
 .Collapsible__trigger:hover {
  display: block;
  font-weight: 400;
  text-decoration: none;
  color: #333;
  position: relative;
  border: 1px solid gray;
  padding: 10px;
  background: lightGray;
  color: #333;
  margin-bottom: 4px;
  margin-top: 4px;
}
  .Collapsible__trigger:after {
    font-family: 'FontAwesome';
    content: '\f107';
    font-size: x-large;
    position: absolute;
    right: 10px;
    top: 10px;
    display: block;
    transition: transform 300ms;
 }
  .Collapsible__trigger.is-open:after {
    transform: rotateZ(180deg);
 }
 .Collapsible__trigger.is-open {
  font-weight: bold;
  background-color: lightgray;
  border-bottom: 3px solid #1E7400
}
  .Collapsible__trigger.is-disabled {
    opacity: 0.5;
    background-color: grey;
 }
  .CustomTriggerCSS {
    background-color: lightcoral;
    transition: background-color 200ms ease;
 }
  .CustomTriggerCSS--open {
    background-color: darkslateblue;
 }
  .Collapsible__custom-sibling {
    padding: 5px;
    font-size: 12px;
    background-color: #cbb700;
    color: black;
 }
  