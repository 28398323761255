.moveup {
    margin-top: -74px;
    margin-left: 170px;
} 

.floating-box {
    float: left;
    width:  auto;
    height: 124px;
    margin: 4px;   
}

.divClear {
    clear: left;
}

.height38 {
  height: 38px;
}

.wh150 {
  width: 150px;
  height: 150px;
  border-width: 0;
  border-color: gray;
  border-style: solid;
  padding: 4px;
}

.thumbbilde {
    cursor: pointer;
    padding: 2px;
    object-fit: cover ;  

    width: 134px;
    height: 134px;
}
   /*  width:100%;
    max-width:140px;
    height: auto; 
  } */

  .stor {
    border: 1px solid #73AD21;    
    width:auto;
    height:auto;
    max-height: 450px;
    max-width: 100%;
    margin: 0 auto;
    position: relative; 
    display: block;
  }

  .stordiv {
    height: 450px;
    max-width: 100%;
    overflow-y: auto;
  }

  .max444 {
    height: auto;
    overflow: hidden;
    overflow-x: hidden;
    margin-bottom: 20px;
}

  .rotate2 {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  .rotate3 {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  .rotate4 {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
    transform: rotateY(180deg);
  }

  .rotate5 {
    -webkit-transform: rotate(270deg);
    -moz-transform: rotate(270deg);
    -o-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
    transform: rotateY(180deg);
  }

  .rotate6 {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    margin-top: 14px;
    max-height: 100px;
  }

  .rotate7 {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
    transform: rotateY(180deg);
  }

  .rotate8 {
    -webkit-transform: rotate(270deg);
    -moz-transform: rotate(270deg);
    -o-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
  }

 .move {
    height: 30px;
    width: 30px;
    
    margin-left: -26px;
    margin-top: -4px;
}

.move2 {
  position: relative;
  margin-left: -20;
  margin-top: 0;
  z-index: 9;
}

.valignTop {
  vertical-align: top;
}

 .moveup {
    position: absolute;
    margin-left: -100px;
    margin-top: -4px;
}

.iconMiddle {
  vertical-align: middle;
}

.north {
  transform:rotate(0deg);
  -ms-transform:rotate(0deg); /* IE 9 */
  -webkit-transform:rotate(0deg); /* Safari and Chrome */
  }
.west {
  transform:rotate(90deg);
  -ms-transform:rotate(90deg); /* IE 9 */
  -webkit-transform:rotate(90deg); /* Safari and Chrome */
  }
.south {
  transform:rotate(180deg);
  -ms-transform:rotate(180deg); /* IE 9 */
  -webkit-transform:rotate(180deg); /* Safari and Chrome */
  }
.east {
  transform:rotate(270deg);
  -ms-transform:rotate(270deg); /* IE 9 */
  -webkit-transform:rotate(270deg); /* Safari and Chrome */

  }

  @media screen and (max-width: 500px) {  
    .del {
        width: 100%;
      }  
  }

  .divbox {
    box-sizing: border-box;
  }

.row::after {
  content: "";
  clear: both;
  display: table;
}

.margin10 {
  margin-left: 10px;
  margin-top: 40px;
}

.rodt {
  color: darkred;
}

.skjul {
  display: none;
}













