.input-group-addon.koordinat {
    width: 70px;
}

.labelmarg {
    margin-top: 8px;
}

.postnr {
    width: 70px;
}

.map {
    height: 300px;
    width: 340px;
}

.tooltipI {
    position: relative;
    display: inline-block;
  }
  
  .tooltipI .tooltiptextI {
    visibility: hidden;
    width: 330px;
    bottom: 100%;
    left: 50%;
    margin-left: -150px;
    background-color: white;
    color: black;
    text-align: left;
    font-size: 12px;
    border-radius: 2px;
    padding: 5px;
    border-width: 1px;
    border-color: darkgreen;
    border-style: solid;
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
  }
  
  .tooltipI:hover .tooltiptextI {
    visibility: visible;
  }
