.modal-content,
.modal-header {
    border-radius: 0;    
}

.modal-header{
    color:#222;
    padding:9px 20px;
    border-bottom:1px solid #eee;
    background-color: #DCDCDC;    
 }

.modal-header .close {
    opacity: 1;
    filter: alpha(opacity=100);
    padding: 0 0 5px 20px;
}
.modal-header .close:hover,
.modal-header .close:focus {
    opacity: .7;
    filter: alpha(opacity=70);
}
.modal-dialog {
    outline: none;
}

.modal-xl{
    width: 100%;
    height: 95%;
    margin: 0;
    margin-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 0px;
    padding-bottom: 5px;
}

.modal-wide{
    width: 70%;
}

.modal-xl .modal-content{
     height: auto;
  min-height: 100%;
  border-radius: 0;
  
}

.modal-fullscreen {
    position: absolute;
    top: 6px;
    left: 6px;
    right: 6px;
    bottom: 6px;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 0px;
    padding-bottom: 5px;
  }

  .nk-modal-footer {
    padding: 15px;
    text-align: right;
    border-top: 1px solid #e5e5e5;
    position: absolute;
    bottom: 0;
    width: 100%;
}

/* to allow the modal to be above the menu and behind contextmenu with z-index 9999 */
.ReactModalPortal {
    position: relative;
    z-index: 9998;
}

.ReactModal__Overlay {
    overflow: auto;
    overflow-y: auto;
}

.ReactModal__Body--open {
    overflow: hidden;
}