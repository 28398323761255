.malspan:hover {
    position: relative;
}

.malspanred:hover {
    position: relative;
}

.malspan[aria-label]:hover:after {
     content: attr(aria-label);
     padding: 4px 8px;
     position: absolute;
     left: -20;
     height: auto;
     width: 400px;
     top: 0;
     white-space: wrap;
     z-index: 20;
     background:#deedd3;
     border-color: green;
     border-style: solid;
     border-width: 1px;
}

.malspanred[aria-label]:hover:after {
    content: attr(aria-label);
    padding: 4px 8px;
    position: absolute;
    left: -20;
    height: auto;
    width: 200px;
    top: 0;
    white-space: wrap;
    z-index: 20;
    background:#e9b5b3;
    border-color: darkred;
    border-style: solid;
    border-width: 1px;
}

.malModalHeight {
    height: calc(100vh - 300px);
}

.malModalFooter {
    padding: 10px;
    text-align: right;
    border-top: 1px solid #e5e5e5;
    border-color: green;
    border-width: 1px;
    position: absolute;
    background-color: whitesmoke;
    bottom: 0;
    width: 100%;
    height: 50px;
}

.malModalMediumLiten {  
    height: 420px;
    position: absolute;
    top: 60px;
    left: calc(50vw - 360px);
    width: 720px;
    bottom: 60px;
    background: whitesmoke;
    border-style: solid;
    border-color: darkgrey;
    border-width: 4px;
  }

  .malModalLiten {  
    height: 200px;
    position: absolute;
    top: 60px;
    left: calc(50vw - 360px);
    width: 300px;
    background: whitesmoke;
    border-style: solid;
    border-color: darkgrey;
    border-width: 4px;
  }

  .malModalLiten300 {  
    height: 300px;
    position: absolute;
    top: 60px;
    left: calc(50vw - 360px);
    width: 300px;
    background: whitesmoke;
    border-style: solid;
    border-color: darkgrey;
    border-width: 4px;
  }

  .malModalMediumStor {  
    position: absolute;
    top: 60px;
    left: calc(50vw - 360px);
    width: 720px;
    bottom: 60px;
    background: whitesmoke;
    border-style: solid;
    border-color: darkgrey;
    border-width: 4px;
  }

.malrow {
    padding: 20px;
    background-color: white;
    height: calc(100vh - 300px);
    overflow-y: auto;
    overflow-x: auto;
}

.timeRange .label {
  color: black;
  font-size: 16px;
  font-weight: normal;
  padding: 0;
}

.timeRange  {
  padding: 0;
}

.malModalSvarut {  
  position: absolute;
  top: 40px;
  left: calc(50vw - 390px) !important;
  width: 808px !important;
  bottom: 40px;
  background: whitesmoke;
  border-style: solid;
  border-color: darkgrey;
  border-width: 4px;
}

.malModalSvarutKommentar {  
  position: absolute;
  top: 40px;
  left: calc(50vw - 420px) !important;
  width: 1110px !important;
  bottom: 40px;
  background: whitesmoke;
  border-style: solid;
  border-color: darkgrey;
  border-width: 4px;
}

.datepicker {
  width: 90px;
  height: 32px;
  margin-left: 4px;
  padding-left: 2px;
}

.datepickerRed {
  width: 90px;
  height: 32px;
  margin-left: 4px;
  padding-left: 2px;
  background-color: #ffff80;
}

.textA {
  position: relative;
}

.text-tooltipA {
  display: none;
  position: absolute;
  top: 100%;
  width: 500px;
  left: 60px;
  padding: 10px;
  color: black;
  border: 1px solid green;
  background-color: #deedd3;
}

.textA:hover .text-tooltipA {
  display: block;
}

