.SortableList {
  position: relative;
  z-index: 9998;
  background-color: #f3f3f3;
  border: 1px solid #efefef;
  border-radius: 3px;
  outline: none;

  width: 100%;
  height: calc(100vh - 180px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  border: 1px solid #e0e0e0;
  list-style: none;
  padding: 0;
}
.SortableListCluster {
  position: relative;
  z-index: 9998;
  background-color: #f3f3f3;
  border: 1px solid #efefef;
  border-radius: 3px;
  outline: none;

  width: 100%;
  max-height: 400px;
  height: auto;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  border: 1px solid #e0e0e0;
  list-style: none;
  padding: 0;
}
.SortableItem {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 74px;
  padding: 0 4px;
  background-color: #fff;
  border-bottom: 1px solid #333;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #333;
  font-weight: 500;
  z-index: 9999;
}
.SortableItemCluster {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 50px;
  padding: 0 10px;
  background-color: #fff;
  border-bottom: 1px solid #333;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #333;
  font-weight: 500;
  z-index: 9999;
}
.SortableHelper {
  box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.2),
    0 -5px 5px -5px rgba(0, 0, 0, 0.2);
  background-color: rgba(255, 255, 255, 0.8);
  cursor: row-resize;
  z-index: 9999;
}

.numberCircle {
  min-width: 30px;
  max-width: 30px;
  height: 30px;
  line-height: 28px;
  border-radius: 50%;
  text-align: center;
  font-size: 16px;
  color: green;
  background-color: yellow;
  border: 2px solid green;
}

.numberCircleRed {
  min-width: 30px;
  max-width: 30px;
  height: 30px;
  line-height: 28px;
  border-radius: 50%;
  text-align: center;
  font-size: 16px;
  color: black;
  background-color: pink;
  border: 2px solid red;
}
.datepicker {
  width: 90px;
  height: 32px;
  margin-left: 4px;
  padding-left: 2px;
}

.datepickerRed {
  width: 90px;
  height: 32px;
  margin-left: 4px;
  padding-left: 2px;
  background-color:  #ffff80;
}

.react-datepicker-popper {
  z-index: 99999 !important;
}

.modalheight {
  height: calc(100vh - 1vw);
}

.modalpadding {
  padding: 4px;
}

.clusterModal {
  height: auto;
  width: 400px;
  position: absolute;
  top: 20px;
  left: 100px;
}

.tdSpace99 {
  max-width: 99%;
  padding-right: 5px;
  vertical-align: top;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.blokk {
  display: inline-block;
}

