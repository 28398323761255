.bfButton {
  height: 40px;
  letter-spacing: 0.5px;
  background-color: white;
  color: #333;
  /*   font-size: 32px; */
  border: 1px solid #1e7400;
  border-radius: 0px;
  cursor: pointer;
}

.bfButtonGreen {
  height: 40px;
  letter-spacing: 0.5px;
  background-color: #1e7400;
  color: white;
  /*   font-size: 32px; */
  border: 1px solid #1e7400;
  border-radius: 0px;
  cursor: pointer;
}

.bfButtonGreen:hover {
  background-color: lightgray;
  border: 3px solid #1e7400;
  color: #333;
}

.bfButton:hover {
  background-color: lightgray;
  border: 3px solid #1e7400;
  color: #333;
}

.bfDropDownButton {
  height: 40px;
  letter-spacing: 0.5px;
  background-color: white;
  color: #333;
  border: 1px solid #1e7400;
  border-radius: 0px;
  cursor: pointer;
  padding-top: 0px;
}

.bfDropDownButton:hover {
  height: 40px;
  letter-spacing: 0.5px;
  background-color: lightgray;
  color: #333;
  border: 3px solid #1e7400;
  border-radius: 0px;
  cursor: pointer;
}

.bfDropDownButtonGreen {
  height: 40px;
  letter-spacing: 0.5px;
  background-color: #1e7400;
  color: white;
  border: 1px solid #1e7400;
  border-radius: 0px;
  cursor: pointer;
  padding-top: 0px;
}

.bfDropDownButtonDisabled {
  height: 40px;
  letter-spacing: 0.5px;
  background-color: rgb(241, 241, 241);
  color: #71797e;
  border: 1px solid #1e7400;
  border-radius: 0px;
  cursor: pointer;
}

.bfDropDownButtonGreen:hover {
  height: 40px;
  letter-spacing: 0.5px;
  background-color: rgb(166, 206, 144);
  color: #333;
  border: 3px solid #1e7400;
  border-radius: 0px;
  cursor: pointer;
}

.bfspan {
  font-weight: normal;
}

.bfspan:hover {
  font-weight: bold;
}

.bfValgt {
  background-color: white;
  border: 1px solid #1e7400;
  border-bottom: 3px solid #1e7400;
  height: 40px;
  letter-spacing: 0.5px;
  font-weight: bold;
  color: #1e7400;
  border-radius: 0px;
  cursor: pointer;
  padding-top: 2px;
  padding-bottom: 0px;
}

.bfValgt:hover{
  background-color: lightgray;
  border: 3px solid #1e7400;
  font-weight: bold;
  color: #1e7400;
  padding-top: 0px;
  padding-bottom: 0px;
}

.bfButtonFarget {
  height: 40px;
  letter-spacing: 0.5px;
  background-color: rgb(166, 206, 144);
  color: black;
  /*   font-size: 32px; */
  border: 1px solid #1e7400;
  border-radius: 0px;
  cursor: pointer;
}

.bfButtonDisabled {
  height: 40px;
  letter-spacing: 0.5px;
  background-color: rgb(241, 241, 241);
  color: Gray;
  /*   font-size: 32px; */
  border: 1px solid Gray;
  border-radius: 0px;
}

.bfButtonLink {
  height: 30px;
  /*       letter-spacing: 0.5px;
      background-color: white;
      color: black;
      border: 1.5px solid green;
      border-radius: 0px;
      cursor: pointer; */
}

.bfLinkValgt {
  border: 1px solid #1e7400;
  height: 30px;
  letter-spacing: 0.5px;
  color: black;
  border-radius: 0px;
}

.bfButtonFarget:hover {
  background-color: #1e7400;
  border: 2.5px solid gray;
  color: white;
}
