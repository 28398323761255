
.testModal {  
  position: absolute;
  top: 20px;
  left: 160px !important;
  width: 808px !important;
  bottom: 40px;
  background: whitesmoke;
  border-style: solid;
  border-color: darkgrey;
  border-width: 4px;
}

.testModalKommentar {  
  position: absolute;
  top: 20px;
  left: 100px !important;
  width: 1110px !important;
  bottom: 40px;
  background: whitesmoke;
  border-style: solid;
  border-color: darkgrey;
  border-width: 4px;
}

/* .fr-box.fr-basic .fr-element {
  padding: 0 !important;
  
} */
.scaleDiv {
  border:1px solid lightgray;
  padding: 10px;
  display:inline-block;
  transform:scale(0.8);
  position:absolute;
  overflow-y: auto;
  left: 10px;
  max-height: 100px;
  max-width: 500px;
 
  transform-origin:top left;
}

.nka-input.nka-input:focus {
  box-shadow: 0px 0px 5px #51a026 !important;
  outline: 3px solid #51a026 !important;
 }

 .nka-collapsible .nka-collapsible-title-bar:focus {
  outline-color: #51a026 !important;
  outline-width: 3px;
  outline-style: solid; }


