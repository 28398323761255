.r_kartenheter {
    height: calc(100vh - 100px);
    width: 100%;
    border-color: green;
    border-width: 1px;     
    border-style: solid;
  }

  .leaflet-container {   
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  
  .hundre {
    height: 100%;
    width: 100%;
  }
  .leaflet-control-zoom-in.leaflet-control-zoom-out {
    width: 48px !important; /*40   34*/
    height: 48px !important;
  }
  
  .leaflet-touch .leaflet-bar a {
    width: 48px !important; /*40   34*/
    height: 48px !important;
  }
  
  .vat-no-focus-outline:focus {
    outline: none;
  }
  
  .vat-hover-highlight:hover {
    background-color: #f6f6f6;
  }
  
  .w150 {
    width: 150px;
    margin-top: 4px;
  }
  
  .w160 {
    width: 160px;
    margin-top: 4px;
  }
  
  .w80 {
    width: 60px;
    margin-top: 2px;
    margin-left: 2px;
  }
  
  .w180 {
    width: 150px;
    margin-top: 4px;
  }
  
  .layerComponent {
    background-color: white;
    border-color: darkgray;
    border-style: solid;
    border-width: 1px;
    padding: 4px;
    margin-left:4px;
  }
  
  .btnLag {
    width: 48px;
    height: 48px;
    margin-bottom: 0;
  }

  .btnLagE {
    height: 48px;
    margin-bottom: 0;
  }
  
  .lagreBoks {
    width: 300px;
    height: 280px;
    background-color: white;
    border-color: darkgray;
    border-style: solid;
    border-width: 1px;
    padding: 4px;
    margin: 4px;
  }
  
  .leaflet-control {
    margin-top: 0 !important;
  }
  
  .w100 {
    width: 300px;
    margin-top: 2px;
    margin-left: 4px;
    margin-right: 4px;
    height: 42px;
  }
  
  .left54 {
    margin-left: 40px;
    top: -2px;
  }
  
  .leggtilBtn {    
    height: 48px;
    padding: 0;
  }
  
  .leaflet-div-icon {
    background-color: orangered;
    /*background-image: url('https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png'); */
  }
  
  .zoomlabel {
    background-color: Gainsboro;
    color: #0078a8;
    font-size: 14px;
  }
  
  .w44 {
    width: 44px;
  }
  
  .leaflet-draw-tooltip {
    background: rgba(161, 196, 56, 0.6);
  
    border: 1px solid #388a8d;
    color: Black;
    font: 12px/18px 'Helvetica Neue', Arial, Helvetica, sans-serif;
    margin-left: 20px;
    margin-top: -21px;
    padding: 4px 8px;
    position: absolute;
    visibility: hidden;
    white-space: nowrap;
    z-index: 6;
  }
  
  .leaflet-poly-icon {
    background: red;
  }
  
  .ltooltip {
    background-color: red;
    font-size: 12px;
  }
  
  .bold {
    font-weight: bold;
    color: darkgreen;
  }
  
  .paa {
    background-color: rgba(161, 196, 56, 0.6);
    border-color: darkgray;
    border-style: solid;
    border-width: 1px;
  }

  .paa:active,
  .paa:target {
    background-color: rgba(161, 196, 56, 0.6);
    border-color: darkgray;
    border-style: solid;
    border-width: 1px;
  }
  
  .av {
    background-color: white;
    border-color: darkgray;
    border-style: solid;
    border-width: 1px;
  }

  .av:active,
  .av:target {
    background-color: white;
    border-color: darkgray;
    border-style: solid;
    border-width: 1px;
  }
  
  .lysNorkartGreen {
    background-color: rgba(161, 196, 56, 1);
  }
  
  .panelBox {
    background-color: white;
    border-color: darkgray;
    border-style: solid;
    border-width: 1px;    
  }
  .fontsvart {
    color: black;
  }
  .text-labels {
    font-size: 16px;
    font-weight: bold;
    color: darkgreen;
    /*text-decoration: underline;*/
    margin-left: 4px !important;
    margin-top: 2px !important;
    text-shadow: 0 0 0.1em white, 0 0 0.1em white, 0 0 0.1em white,0 0 0.1em white,0 0 0.1em;
  }
  
  .subPanel {
    margin-left: 2px;
    /*display: inline-block;  */
  }

  .redText {
    color: red;
  }

  .height340 {
    height: 340px;
  }

  .height400 {
    height: 400px;
  }

  .height440 {
    height: 440px;
  }

  .farger {
    height: 34px;
    width: 100%;
  }

  .regionerScroll {
    overflow-y: scroll; 
    height: auto;
    max-height: 400px;
    background: white;
    padding: 6px;
    
  }

  .bregion {
    width: 306px;  
    height: 36px;  
    margin-bottom: 4px;
    font-size: 14px;  
  }

  .bgruppe {
    width: 100%;
    height: 36px;  
    font-size: 14px;
    font-weight: bold; 
  }

  .bslett {
    width: 20;
    height: 36px;  
    margin-bottom: 4px;
  }

  .bslett:hover {
    width: 20;
    height: 36px;  
    margin-bottom: 4px;
    background-color: red;
    color: white;
  }

  input[type="radio"] {
    /* -ms-transform: scale(2); */ /* IE 9 */
   /*  -webkit-transform: scale(2); */ /* Chrome, Safari, Opera */
    /* transform: scale(2); */
   }


  .SnapDiv {
    background-color: white;
    border-color: black;
    border-width: 1px;
    border-style: solid;
    padding-left: 20px;
    padding-right: 14px;
  }
  .polyTooltip {
    color: green;
    font-size: 16px;
    background: white;
    border-color: green;
    border-style: solid;
    border-width: 1px;
  }
   .polyTooltip:hover {    
    color: blue;
    font-size: 16px;
    background: white;
    text-decoration: underline;
    border-color: green;
    border-style: solid;
    border-width: 1px;
  } 
  .leaflet-tooltip-left.polyTooltip::before {
    border-left-color: green;
  }
  .leaflet-tooltip-right.polyTooltip::before {
    border-right-color: green;
  }
  .faIcon {
    font-size: 26px;
  }

  .w200 {
    width: 360px;  
  }

  .popup {
    width: 200px;
    height: 200px;
  }

  .btnOmrade {
    width: 100%;
    margin-top: 4px;
    border-style: solid;
    border-color: darkgreen;
    border-width: 1px;
  }

  .divOmrade {
    width: 100%;
    height: 42px;
    border-bottom-color: darkgray;
    border-bottom-width: 1px;
    border-bottom-style: solid;
  }

  .divLabel {
    margin-top: 6px;
    margin-left: 4px;

  }

  .divEdit {
    
    margin-left: 4px;
    width: 100%;
  }

  .td80 {
    width: 472px;
    height: 42px;
    border-bottom-color: darkgray;
    border-bottom-style: solid;
    border-bottom-width: 1px;
  }

  .tdRight {
    height: 42px;
    width: 82px;
    text-align: right;
  }

  .table100 {
    width: 100%;  
  }

  .iedit {
    height: 30px;
    width: 100%;
  }

  .bedit {
    height: 40px;
    width: 44px;
  }

  .bsletto {
    width: 44;
    height: 40px;  
  }

  .bsletto:hover {
    width: 44;
    height: 40x;  
    background-color: red;
    color: white;
  }

  .regionerScroll2 {
    overflow-y: scroll; 
    height: auto;
    max-height: 400px;
    background: white;
    padding: 6px;
    height: 400px;
    
  }

.arrow-toggle .icon-arrow-down,
.arrow-toggle.collapsed .icon-arrow-up {
    display: inline-block;
}
.arrow-toggle.collapsed .icon-arrow-down,
.arrow-toggle .icon-arrow-up {
    display: none;
}

.mb4 {
  margin-bottom: 4px;
  border-color: darkgreen;
  border-style: solid;
  border-width: 1px;
}

.soneendre {
  width: 30;
  height: 36px;  
  margin-bottom: 4px;
}

.padding10 {
  padding: 10px;
}

.height380 {
  height: 380px;
}

.slidecontainer {
  width: 340px;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 15px;
  border-radius: 5px;  
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%; 
  background: #51a026;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #51a026;
  cursor: pointer;
}



 
     