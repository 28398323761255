.ellipsisFag {
    white-space: nowrap; 
    width: 76px; 
    overflow: hidden;
    text-overflow: ellipsis;
  }

.inner
{
   /*  display: inline-block; */
    margin-right: 8px;
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
}

