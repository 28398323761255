
.w110 {
  width: 110px;
  height: 40px;
}

.thumbbilde {
    cursor: pointer;
    padding: 2px;
    object-fit: cover ;  
    width: 134px;
    height: 134px;
}


  .stor {
    border: 1px solid #73AD21;    
    width:auto;
    height:auto;
    max-height: 700px;
    max-width: 100%;
    margin: 0 auto;
    position: relative; 
    display: block;
  }

  .stordiv {
    height: 750px;
    max-width: 100%;
    overflow-y: auto;
  }

  .rotate2 {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  .rotate3 {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  .rotate4 {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
    transform: rotateY(180deg);
  }

  .rotate5 {
    -webkit-transform: rotate(270deg);
    -moz-transform: rotate(270deg);
    -o-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
    transform: rotateY(180deg);
  }

  .rotate6 {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    margin-top: 14px;
    max-height: 100px;
  }

  .rotate7 {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
    transform: rotateY(180deg);
  }

  .rotate8 {
    -webkit-transform: rotate(270deg);
    -moz-transform: rotate(270deg);
    -o-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
  }

.north {
  transform:rotate(0deg);
  -ms-transform:rotate(0deg); /* IE 9 */
  -webkit-transform:rotate(0deg); /* Safari and Chrome */
  }
.west {
  transform:rotate(90deg);
  -ms-transform:rotate(90deg); /* IE 9 */
  -webkit-transform:rotate(90deg); /* Safari and Chrome */
  }
.south {
  transform:rotate(180deg);
  -ms-transform:rotate(180deg); /* IE 9 */
  -webkit-transform:rotate(180deg); /* Safari and Chrome */
  }
.east {
  transform:rotate(270deg);
  -ms-transform:rotate(270deg); /* IE 9 */
  -webkit-transform:rotate(270deg); /* Safari and Chrome */

  }

  @media screen and (max-width: 500px) {  
    .del {
        width: 100%;
      }  
  }














