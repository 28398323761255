
  .FullModal {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: whitesmoke;
    border-style: solid;
    border-color: darkgrey;
    border-width: 4px;
  }
 .FullHeader {
   background:#DCDCDC;
   padding:8px;
   color: Black;
   height: 40px;
   border-bottom-style: solid;
   border-bottom-color: green;
   border-bottom-width: 1px;
   margin-bottom: 0 !important;
}
.FullBody {
    background: whitesmoke;
    color: Black;
}
.FullFooter {
   background:whitesmoke;
   padding:8px;
   color: Black;
   height: 48px;
   border-top-style: solid;
   border-top-color: green;
   border-top-width: 1px;
   border-bottom-style: solid;
   border-bottom-color: darkgrey;
   border-bottom-width: 4px;
   margin-right: 12px;
   margin-left: 10px;
}

.FullFooterBtn {
    margin-bottom: 4px;
    margin-right: 4px;
    height: 34px;
}

.FButton {
  margin-right: 20px;
  margin-top: 2px;
}

.FButtonKart {
  margin-right: 10px;
  margin-top: 2px;
}

.FModal {
    position: absolute;
    top: 6px;
    left: 6px;
    right: 6px;
    bottom: 6px;
    background: whitesmoke;
    border-style: solid;
    border-color: darkgrey;
    border-width: 4px;
  }

  .FModalMedium {
    position: absolute;
    top: 60px;
    left: 60px;
    right: 60px;
    bottom: 60px;
    background: whitesmoke;
    border-style: solid;
    border-color: darkgrey;
    border-width: 4px;
  }

  .FModalSmall {
    position: absolute;
    top: 100px;
    left: 100px;
    right: 100px;
    bottom: 100px;
    background: whitesmoke;
    border-style: solid;
    border-color: darkgrey;
    border-width: 4px;
  }

  .FHeader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    min-height: 48px;
    padding-left: 10px;
    padding-top: 4px;
    background:lightgray;
    border-bottom-style: solid;
    border-bottom-color: darkgrey;
    border-bottom-width: 1px;
}

.FFooter {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    min-height: 48px;
    padding-left: 10px;
    padding-top: 4px;
    border-top-style: solid;
    border-top-color: green;
    border-top-width: 1px;
}

.FScroller {
    padding: 4px;
    height: calc(100vh - 88px);
    overflow: auto;
    overflow-x: hidden;
}


