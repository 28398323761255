/* You will almost certainly want to be able to scroll the content inside
 * your component if it extends off screen (long text for example).
 */
.fullscreen .demo-component {
    overflow-y: scroll;
}

.fullpanell {
    background: white;
    border: 1px solid gray;
    width: 100%;
    padding: 2px;
    height: 100%;
}

.vis {
    display: block;
    height: 100%;
    width: 100%;
}

.w100 {
    width: 100%;
}

.w60px {
    width: 60px;
    vertical-align: text-bottom;
}

.hundre {
    height: 100%;
    width: 100%;
}

.skjul {
    display: none;
}

.samme {
    display: inline-block;
}

.sammelinje {   
    margin-left: 4px;
    margin-right: 8px;
    margin-top: 2px;
    display: inline-block;
} 

.sammelinje2 {
    margin-right: 8px;
    margin-top: 2px;
    display: inline-block;
} 

.sammelinje3 {
    margin-right: 4px;
    display: inline-block;
}

.sammelinje4 {
    margin-right: 8px;
    margin-top: 2px;
    display: inline-block;
    width: 200px;
} 

.tittel {
    height: 30px;
    padding-left: 10px;
}


.margin6 {
    margin-left: 6px;
    margin-right: 6px;
}

.margin4 {
    margin-right: 4px;
}


.mediumbutton {
    width: 100%; 
    max-height: 49px;
    margin-top: 2px;
}

.situasjon-knapp {
    margin-left: 5px;
    margin-top: 4px;
}

.del1 {
  /*  padding: 4px; */
    height: 100%;
    
}

.del2 {
  /*  padding: 4px;  */
   height: 100%;
}

.anm-scroller {
  /*     overflow: auto;
    max-height: 80%;
  max-height: calc(100% - 100px);  */

    height: calc(100vh - 200px);
    overflow: auto;
    overflow-x: hidden;
}

.avv-scroller {
    /*     overflow: auto;
      max-height: 80%;
    max-height: calc(100% - 100px);  */
  
      height: calc(100vh - 200px);
      overflow: auto;
      overflow-x: hidden;
  }

.publiTable {
    width: 100%;
}

.publiTd {
    width: 110px;
}

.tdalign {
    vertical-align: top;
    border-bottom: 0.5px solid lightgray;  
}

.tdalign2 {
    vertical-align: top;
    border-bottom: 0.5px solid lightgray;    
    border-top: 0.5px solid lightgray;
}

.fet {
    font-weight: bold;
}

.malbeskr {
    font-size: 14px;
}

.malboksgray {
    min-height: 80px;
    width: 100%;
    margin-left: 2px;
    margin-bottom: 2px;
    padding: 4px;
    border: 1px solid whitesmoke;
}


.malboksgreen {
    min-height: 80px;
    width: 100%;
    margin-left: 2px;
    margin-bottom: 2px;
    padding: 4px;
    border: 1px solid green;
}

.malboksred {
    min-height: 80px;
    width: 100%;
    margin-left: 2px;
    margin-bottom: 2px;
    padding: 4px;
    border: 1px solid red;
}

.malboksblue {
    height: 60px;
    width: 100%;
    margin-left: 2px;
    margin-bottom: 2px;
    padding: 4px;
   /* border: 1px solid dodgerblue;  */
}

.magisktekst {
  width: 100%;
  height: 80px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
}

.malboks:hover {
    background-color: lightgray;
}

.maltittel {
    font-size: 16px;
    font-weight: bold;
}

.pad2 {
    padding-top: 0;
    padding-left: 4px;
    padding-right: 4px;
    padding-bottom: 4px;
}

.block-with-text {
  /* hide text if it more than N lines  */
  overflow: hidden;
  /* for set '...' in absolute position */
  position: relative; 
  /* use this value to count block height */
  line-height: 1.2em;
  /* max-height = line-height (1.2) * lines max number (3) */
  max-height: 4.6em; 
  /* fix problem when last visible word doesn't adjoin right side  */
  text-align: justify;
  /* */
  margin-right: -1em;
  padding-right: 1em;
}
.block-with-text:before {
  /* points in the end */
  content: '...';
  /* absolute position */
  position: absolute;
  /* set position to right bottom corner of block */
  right: 0;
  bottom: 0px;
}
.block-with-text:after {
  /* points in the end */
  content: '';
  /* absolute position */
  position: absolute;
  /* set position to right bottom corner of text */
  right: 0;
  width: 1em;
  /* set width and height */
  height: 1em;
  margin-top: 0.2em;
  background: white;
}

.ddiv {
    padding-left: 8px;
    padding-right: 14px;
    text-align: left;
}

.ddiv0 {
    padding-right: 14px;
}

.idiv {
    padding-left: 12px;
    padding-right: 14px;
}

.max390 {
    max-height: 390px;
}

.keepBottom {
  position: absolute;
  bottom: 0;
  right: 50px;
  margin-bottom: 4px;
}

.formmargin {
    margin-left: 12px;
    margin-right: 10px;
    margin-top: 2px;
}

.aboks {
    border-left: 1px solid gray;
    
}

.hrline {
    height:2px;
    border-width:0;
    color:gray;
    background-color:gray
}

.padding8 {
    padding: 12px;
}

.h30 {
    height: 30px;
}

.wvelg {
    width: 80px;
    margin-right: 10px;
}

.verticalTop {
    vertical-align: top;
    height: 20px;
}

.scrolldiv {
    height: 400px;
    overflow: auto;
}

.scrollParagrafer {
    /* height: 50px; 
    overflow-y : auto; */
    font-size: 12px;
}


.scrollCheckParagrafer {
    height: 300px; 
    overflow-y : auto;
}

.checkparagraf{
    float: left;
}

.exTab1 .tab-content {
  padding : 5px 5px;
/*  border-left: 1px solid lightgray;
  border-right: 1px solid lightgray;
  border-bottom: 1px solid lightgray; */
}

.s100in {
    width: 100%;
    height: 120px;
    resize: none;
}

.margin4 {
    margin-top: 4px;
}

.margin8all {
    margin: 8px;
}

.margintop8 {
    margin-top: 8px;
}

.margintop12 {
    margin-top: 12px;
}

.marginTekstDialog{
    margin-left: 5px;
    margin-top: 7px;
}

.margin-left-5 {
    margin-left: 5px;
}
.margin-left-10 {
    margin-left: 10px;
}
.margin-right-10 {
    margin-right: 10px;
}
.ridge {
    border-width: 1px;
    border-color: gray;
    border-style: solid;
    padding: 4px;
}


.apoint {
    cursor: hand;
    cursor: pointer;
}

.nopadding-left {
    padding-left: 0px;
}


.nopadding-right {
    padding-right: 0px;
}

.lukketText {
    margin-left: 8px;
    margin-top: 4px;   
}

.hh38 {
    height: 38px;
}

.h34 {
    height: 34px;
}

.hh24 {
    height: 24px;
}

.slettDiv {
    margin-left: 60px;
    margin-top: 100px;
}

.marginleft200 {
    margin-left: 100px;
}

.w40 {
    height: 34px;
    width: 40px;
    padding-left: 8px;
    margin-right: 4px;
}

.w80 {
    width: 100px;
    height: 36px;
}

.m32 {
    margin-left: 32px;
}

.w95 {
    width: 84%;
    margin-bottom: 2px;
    margin-right: 2px;
    text-align: left;
    min-height: 48px;
}

.w95b {
    width: 80%;
    margin-bottom: 2px;
    margin-right: 2px;
    text-align: left;
    min-height: 48px;
}

.marginBottom2 {
    margin-bottom: 2px;
}

.w5 {
    width: 5%;
}

.mt5 {
    margin-top: 5px;
}

.aleft {
    text-align: 'left';
}

.tleft {
    text-align: left;
}

div.textContainer {
    text-align: left;
}

div.textContainer p {
    display: inline-block;
    text-align: left;
}

.iblock {
    display: inline-block;
    text-align: left;
}

.d_red {
    color: brown;
}

.font12 {
    font-size: 12px;
}

.Select--multi .Select-value-icon {
    background-color: white !important ;
}

.Select--multi .Select-value-label {
    background-color: white !important ;
}

.bildewh {
    width: 304px;
    height: 236px;
}

.min15 {
    padding-right: 0 !important;
    padding-left: 0 !important;
}

.font9 {
    font-size: 9px;
}


.mr32 {
    margin-right: 32px;  
}

.m15 {
    margin-right: 15px;  
    margin-left: 15px;
}

.thumb {
    height: 150px;
    border: 1px solid green;
   /* margin: 10px 5px 0 0;  */
  }

  .thumb2 {
    border: 1px solid green;
    width: 100%;
    height: 100%;    
  }

.move {
    height: 30px;
    width: 30px;
    position: absolute;
    margin-left: -26px;
    margin-top: -4px;
}

.bildebutton {
    padding: 2px;
    margin: 2px;
}

.moveup {
    margin-top: -74px;
    margin-left: 170px;
}

.moveup2 {
    margin-top: -74px;
    margin-left: 240px;
}

.datowidth {
    width: 84px;
    margin-left: 4px;
}

.has-value.Select--single > .Select-control .Select-value a.Select-value-label:hover,
.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value a.Select-value-label:hover,
.has-value.Select--single > .Select-control .Select-value a.Select-value-label:focus,
.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value a.Select-value-label:focus {
  color: #007eff; 
  outline: none;
  text-decoration: underline;
}

.avvikheight {
    min-height: 800px;
}

.anmerkningheight {
    min-height: 770px;
}

.height700 {
    height: 700px;
}

.min550 {
    min-height: 550px;
}

.w500 {
    height: 500px;
}

.tekstbit {
    border: 1px solid green;
    padding: 4px;
    word-wrap: break-word;   
    overflow-wrap: break-word;
    word-break: break-word;
}

.divTekstbiter {
    margin: 10px;
}

.width120Heigth32 {
    width: 120px;
    height: 32px;
}

.margtop20 {
    margin-top: 20px;
}

.textleft {
    text-align: left;
    margin-left: 4px;
}

.textright {
    text-align: right;
    margin-right: 4px;
}

.btntable {
    width: 100%;
}

.table100Dialog {
    width: 100%;
    table-layout: fixed;
    white-space: pre-line;
    text-align: left;
}

.btntd0 {
    width: 5%;
}

.btntd1 {
    width: 45%;
    text-align: left;
    font-size: 16px;
    font-weight: bold;
}

.btntd2 {
    width: 50%;
    text-align: right;
    font-size: 16px;
    font-weight: bold;
    padding-right: 8px;
}

.btntd3 {
    width: 45%;
    text-align: right;
    font-size: 16px;
    font-weight: bold;
    padding-right: 8px;
}

.max100 {
    height: 60px;
}



.fontNormal {
    font-weight: normal;
}

.highlight {
    color:green;
}

mark {
    background-color: lightgreen;
    color: black; }

.m6 {
    margin-left: 6px;
}

.switchAapen {
    background-color:  #e25653;
} 

.switchPara {
    background-color:  #51a026;
} 

.height48 {
    height: 48px;
}

.tdTop {
    vertical-align: top;
}
.sjekkboksAvvik {
    width: 30px;
    height: 30px;
}
.sjekkboksAnm {
    width: 30px;
    height: 30px;
}
.ml8 {
    margin-left: 8px;
}

.mr8 {
    margin-right: 8px;
}
.p100 {
    width: 100%;
}
.w50 {
    width: 50px;
}
.w100 {
    width: 100px;
}
.w150 {
    width: 150px;
}

.ghost {
    display: none;
}

.panel-enhet-dialog {
    width: 95%;
    min-height: 48px;
    /* max-height: 72px; */
    margin-bottom: 4px;
    font-size: 16px;
}


.tdSpace{
    padding-right: 5px;
}

.tdSpace0{
    padding-right: 5px;
    width: 140px;
}

.tdSpace20{
    width: 30px;
    padding-right: 5px;
    vertical-align: top;
}

.tdSpace60{
    width: 90px;
    padding-right: 5px;
    vertical-align: top;
}

.tdSpace99{
    max-width: 99%;
    padding-right: 5px;
    vertical-align: top;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;  
}

.collapsPanel {
    width: 100%;
    
}

.arrow-toggle .icon-arrow-down,
.arrow-toggle.collapsed .icon-arrow-up {
    display: inline-block;
}
.arrow-toggle.collapsed .icon-arrow-down,
.arrow-toggle .icon-arrow-up {
    display: none;
}

.maxHeight400Scroll {
    max-height: 400px;
    overflow-y:auto;
}

.maxHeight600Scroll {
    max-height: 600px;
    overflow-y:auto;
}

.marginTop10 {
    margin-top: 11px;
}

.liste { padding-left: 1.2em; }

.tdwidth30 {
    width: 30px;
}
.red {
    color: red;
  }

     .fr-view.fr-element {
        padding: 10px !important;
        margin-left: 15px !important;
      } 

 
    
    .fr-box, 
    .fr-basic, 
    .fr-wrapper, 
    .fr-second-toolbar,
    .fr-element {
        border-radius: 0 !important;
}

.col1-content {
    display: flex;
    flex-wrap: wrap;
  }
  
  .part {
    flex: 1 1 50%; /* Grow and shrink as needed, with a base width of 50% */
    box-sizing: border-box;
     /* Adjust padding as needed */
  }










