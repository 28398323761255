.sk-navbar .icon {
    font-size: 16px;
}

.sk-logo {
    padding-right: 2px;
}

.brannBrand{
		margin-top: 10px;
	}