@charset "UTF-8";
/* SEARCHKIT */
.sk-search-box {
  border: 1px solid #ccc;
  margin-bottom: 5px;
  height: 35px;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
  line-height: 60px;
  background-color: #f5f5f5;
}

/*  Hardkoda høyde på modalt vindu for å få scrollbar, dette bør løses på en annen måte    */
.modal-body.fixedbody {
  height: calc(100vh - 130px);
  overflow-y: auto;
}

.fixedsidebar {
  max-height: calc(100vh - 140px);
  overflow-x: auto;
  overflow-x: hidden;
}

.fixedresults {
  max-height: calc(100vh - 140px);
  overflow-y: auto; 
}

.autosizeMap {
  height: calc(100vh - 130px);
  overflow-y: auto;
  -webkit-transition: margin 0.3s ease;
  -moz-transition: margin 0.3s ease;
  -o-transition: margin 0.3s ease;
  transition: margin 0.3s ease;
}

.selectMinWidth {
  min-width: 350px;
}

/*  Footer legg til enheter */
.tiltakLabel {
  margin-right: 1px;
}

.tiltakBadge {
  margin-right: 5px;
}

.withoutScrollbar {
  overflow: hidden !important;
  overflow-y: hidden !important;
}

/*  For å få til horisontal deling av grid og kart, sikkert ikke særlig responsivt  */
.panel.panel-horizontal {
  display: table;
  width: 100%;
}

.panel.panel-horizontal > .panel-heading,
.panel.panel-horizontal > .panel-body,
.panel.panel-horizontal > .panel-footer {
  display: table-cell;
}

.panel.panel-horizontal > .panel-heading,
.panel.panel-horizontal > .panel-footer {
  width: 100%;
  border: 0;
  vertical-align: top;
}

.rowSok {
  margin-left: 0px;
  margin-right: 0px;
  /* necessary to hide collapsed sidebar */
}

.nopadding {
  padding-bottom: 0px !important;
  margin-bottom: 0px !important;
}

#content {
  -webkit-transition: width 0.3s ease;
  -moz-transition: width 0.3s ease;
  -o-transition: width 0.3s ease;
  transition: width 0.3s ease;
}

#content2 {
  -webkit-transition: width 0.3s ease;
  -moz-transition: width 0.3s ease;
  -o-transition: width 0.3s ease;
  transition: width 0.3s ease;
}

#sidebar {
  overflow-y: hidden;
  -webkit-transition: margin 0.3s ease;
  -moz-transition: margin 0.3s ease;
  -o-transition: margin 0.3s ease;
  transition: margin 0.3s ease;
}

#nesteButton {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
  line-height: 60px;
  background-color: #f5f5f5;
}

.sokHeader {
  margin-top: 5px;
}

#visKart {
  max-width: 90px;
}

/* 04.07.2018: Flemming: Quickfix for 100937 */
#legg-til-bruksenheter, #enhetssok {
  box-sizing: border-box;
}

#legg-til-bruksenheter .content-wrapper, #enhetssok .content-wrapper {
  padding-left: 0px;
}

#legg-til-bruksenheter .arbeidsliste, #legg-til-bruksenheter .matrikkelinfo, #enhetssok .arbeidsliste, #enhetssok .matrikkelinfo {
  padding-left: 0px;
  padding-right: 0px;
  /*background-color: white;*/
  position: relative;
  overflow-y: hidden;
  height: calc(100vh - 110px);
  max-height: calc(100vh - 110px);
}

#legg-til-bruksenheter .arbeidsliste .scrollable, #legg-til-bruksenheter .matrikkelinfo .scrollable, #enhetssok .arbeidsliste .scrollable, #enhetssok .matrikkelinfo .scrollable {
  overflow-y: scroll;
  height: calc(100% - 90px);
}

#legg-til-bruksenheter .matrikkelinfo .scroller, #enhetssok .matrikkelinfo .scroller {
  /* Disable component scroller in favour of .srcollable (above) */
  overflow: unset;
  overflow-y: unset;
  height: unset;
}

#legg-til-bruksenheter .sk-pagination-navigation {
  width: 220px;
}

#legg-til-bruksenheter .searchkit-view-switcher .kartenheter {
  /* Dette er langt nede i DOMen der... */
  height: calc(100vh - 18.5vw);
}

.text-tooltip {
  display: none;
  position: absolute;
  top: 100%;
  width: 750px;
  left: 20px;
  padding: 10px;
  color: black;
  border: 1px solid green;
  background-color: #deedd3;
  -ms-transform: scale(0.6);
  -moz-transform: scale(0.6);
  -o-transform: scale(0.6);
  -webkit-transform: scale(0.6);
  transform: scale(0.6);
  
  -ms-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}