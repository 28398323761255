.gj-icon-green{
    width: 26px !important;
    height: 26px !important;
    margin-left: -13px !important;
    margin-top: -13px !important;
    border-radius: 18px;
    border: 2px solid green;
    text-align: center;
    color: black;
    background-color: yellow;
    font-size: 16px;
  }

  .gj-icon-blue{
    width: 26px !important;
    height: 26px !important;
    margin-left: -13px !important;
    margin-top: -13px !important;
    border-radius: 18px;
    border: 2px solid blue;
    text-align: center;
    color: black;
    background-color: yellow;
    font-size: 16px;
  }

  .gj-icon-red{
    width: 26px !important;
    height: 26px !important;
    margin-left: -13px !important;
    margin-top: -13px !important;
    border-radius: 18px;
    border: 2px solid red;
    text-align: center;
    color: red;
    background-color: yellow;
    font-size: 16px;
  }

  .gj-icon-orange{
    width: 26px !important;
    height: 26px !important;
    margin-left: -13px !important;
    margin-top: -13px !important;
    border-radius: 18px;
    border: 2px solid orangered;
    text-align: center;
    color: black;
    background-color: yellow;
    font-size: 16px;
  }

  .gj-icon-no-telefon{
    width: 26px !important;
    height: 26px !important;
    margin-left: -13px !important;    
    margin-top: -13px !important;
    border-radius: 18px;
    border: 2px solid red;
    text-align: center;
    color: black;
    background-color: pink;
    font-size: 16px;
  }

  .gj-icon-green-stor{
    width: 46px !important;
    height: 46px !important;
    margin-left: -13px !important;
    margin-top: -13px !important;
    border-radius: 38px;
    border: 2px solid green;
    text-align: center;
    color: green;
    background-color: yellow;
    font-size: 20px;
  }

  .gj-icon-tid-red{
    width: 80px !important;
    height: 24px !important;
    margin-left: -19px !important;
    margin-top: -10px !important;
    border: 2px solid red;
    text-align: center;
    color: red;
    background-color: yellow;
    font-size: 14px;
  }

  .gj-icon-tid-green{
    width: 80px !important;
    height: 24px !important;
    margin-left: -19px !important;
    margin-top: -10px !important;
    border: 2px solid green;  
    text-align: center;
    color: black;
    background-color: yellow;
    font-size: 14px;
  }

  .gj-icon-tid-blue{
    width: 80px !important;
    height: 24px !important;
    margin-left: -19px !important;
    margin-top: -10px !important;
    border: 2px solid blue;  
    text-align: center;
    color: black;
    background-color: yellow;
    font-size: 14px;
  }

  .gj-icon-tid-orange{
    width: 80px !important;
    height: 24px !important;
    margin-left: -19px !important;
    margin-top: -10px !important;
    border: 2px solid orangered;  
    text-align: center;
    color: black;
    background-color: yellow;
    font-size: 14px;
  }