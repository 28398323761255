
.SortableListRekke {
  position: relative;
  z-index: 9998;
  background-color: #efefef;;
  border: 1px solid #efefef;
  border-radius: 3px;
  outline: none;
  width: 100%;
  max-height: 66vh !important;
 /*  height: 66vh; */
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  border: 1px solid #e0e0e0;
  list-style: none;
  padding: 0;
}

.SortableItemRekke {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 50px;
  padding: 0 10px;
  background-color: #fff;
  border-bottom: 1px solid #333;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #333;
  font-weight: 500;
  z-index: 9999;
}
.SortableRekkeHelper {
  box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.2),
    0 -5px 5px -5px rgba(0, 0, 0, 0.2);
  background-color: rgba(255, 255, 255, 0.8);
  cursor: row-resize;
  z-index: 9999;
}

.numberCircle {
  min-width: 30px;
  max-width: 30px;
  height: 30px;
  line-height: 28px;
  border-radius: 50%;
  text-align: center;
  font-size: 16px;
  padding-left: 2px !important;
  padding-top: 0px !important;
  color: green;
  background-color: yellow;
  border: 2px solid green;
}

.numberCircleRed {
  min-width: 30px;
  max-width: 30px;
  height: 30px;
  line-height: 28px;
  border-radius: 50%;
  text-align: center;
  font-size: 16px;
  color: black;
  background-color: pink;
  border: 2px solid red;
}
.rKart2 {
  max-height: calc(66vh - 2px)  !important;
  height: calc(66vh - 2px)  !important;
}
 .rKart {
  max-height: 66vh  !important;
  height: 66vh;
  float: left;
  width: 48%;
  margin: 0;
  padding: 0;
  border: 1px solid gray;
}
.rListe {
  max-height: 66vh !important;
  height: 66vh !important;
  margin-right: 10px;
  float: left;
  width: 50%;
  margin-right: 10px;
  padding: 0;
  border: 1px solid gray;
}

.rListe100 {
  max-height: calc(66vh - 2px)  !important;
  height: calc(66vh - 2px)  !important;
  margin-right: 10px;
  float: left;
  width: 98%;
  margin-right: 10px;
  padding: 0;
  border: 1px solid gray;
}