.xradio-item {
    display: inline-block;
    position: relative;
    padding: 0 6px;
    margin: 14px 0 0;
  }
  
  .xradio-item input[type='radio'] {
    display: none;
  }
  
  .xradio-item label {
    color: black;
    font-weight: bold;
  }
  
  .xradio-item label:before {
    content: " ";
    display: inline-block;
    position: relative;
    top: 5px;
    margin: 0 15px 0 0;
    width: 24px;
    height: 24px;
    border-radius: 11px;
    border: 2px solid black;
    background-color: transparent;
  }
  
  .xradio-item input[type=radio]:checked + label:after {
    border-radius: 11px;
    width: 16px;
    height: 16px;
    position: absolute;
    top: 9px;
    left: 10px;
    content: " ";
    display: block;
    background: red;
  }

  .yradio-item {
    display: inline-block;
    position: relative;
    padding: 0 6px;
    margin: 14px 0 0;
  }
  
  .yradio-item input[type='radio'] {
    display: none;
  }
  
  .yradio-item label {
    color: black;
    font-weight: bold;
  }
  
  .yradio-item label:before {
    content: " ";
    display: inline-block;
    position: relative;
    top: 5px;
    margin: 0 15px 0 0;
    width: 24px;
    height: 24px;
    border-radius: 11px;
    border: 2px solid black;
    background-color: transparent;
  }
  
  .yradio-item input[type=radio]:checked + label:after {
    border-radius: 11px;
    width: 16px;
    height: 16px;
    position: absolute;
    top: 9px;
    left: 10px;
    content: " ";
    display: block;
    background: green;
  }

  .text {
    position: relative;
  }
  
  .text-tooltipDM {
    display: none;
    position: absolute;
    top: 100%;
    width: 450px;
    right: 0px;
    padding: 10px;
    color: blue;
    border: 1px solid green;
    background-color: #deedd3;
    z-index: 9999;
  }

  .text-tooltipDM2 {
    display: none;
    position: absolute;
    top: 100%;
    width: 450px;
    right: 0px;
    font-size: 12px;
    padding: 10px;
    color: black;
    border: 1px solid green;
    background-color: #deedd3;
    z-index: 9999;
  }

  .text:hover .text-tooltipDM2 {
    display: block;
  }

  .text:hover .text-tooltipDM {
    display: block;
  }