.input-group-addon.koordinat {
  width: 70px;
}

.labelmarg {
  margin-top: 4px;
}

.postnr {
  width: 70px;
}

.map {
  height: 300px;
  width: 340px;
}

.tooltipI {
  position: relative;
  display: inline-block;
}

.tooltipI .tooltiptextI {
  visibility: hidden;
  width: 330px;
  bottom: 100%;
  left: 50%;
  margin-left: -150px;
  background-color: white;
  color: black;
  text-align: left;
  font-size: 12px;
  border-radius: 2px;
  padding: 5px;
  border-width: 1px;
  border-color: darkgreen;
  border-style: solid;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltipI:hover .tooltiptextI {
  visibility: visible;
}

.fileInput {
  border: 1px solid gray;
  padding: 10px;
  width: 598px;
  cursor: pointer;
}

.imgPreview {
  text-align: left;
  height: 100px;
  width: 600px;
  max-height: 100px;
  max-width: 600px;
  border: 1px solid gray;
  object-fit: cover;
}

.previewText {
  width: 100%;
  margin-top: 20px;
}


.centerText {
  text-align: center;
  width: 500px;
}
