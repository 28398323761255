 .rbc-today {
    /* border-color: #51a026;
    border-style: solid; */
     background-color: rgb(222, 237, 211) !important; 
} 

.rbc-event {
    padding: 0 !important;
    max-height: 27px;
    margin: 0 !important;
    vertical-align: text-top;
}

.rbc-event-content {
   padding: 0 !important;
   margin: 0 !important;
   vertical-align: text-top;
}

.rbc-current-time-indicator {
   height: 2px;
}
