.leaflet-container {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.kartenheter {
  height: calc(100vh - 17vw);
  width: 100%;
}

 .kartenheterFxModal {
  height: calc(100vh - 60px);  
  width: 100%;
} 

.planleggingskart {
  height: calc(100vh - 270px);
  width: 100%;
}

.kartgjennomfoering {
  height: calc(100vh - 6vw);
  width: 100%;
}

.hotels {
  width: 250px;
  height: 204px;
  background-color: white;
  opacity: 1;
  border-style: solid;
  border-width: 1px;  
  border-color: gray;
  padding: 10px;
}   

.hotelslink {
  color: blue; 
  cursor: pointer;
}

.hotelstop {
  vertical-align: top;
  cursor: pointer;
}
.t100 {
  width: 100%;
}
.hundre {
  height: 100%;
  width: 100%;
}
.leaflet-control-zoom-in.leaflet-control-zoom-out {
  width: 48px !important; /*40   34*/
  height: 48px !important;
}

.leaflet-touch .leaflet-bar a {
  width: 48px !important; /*40   34*/
  height: 48px !important;
}

.vat-no-focus-outline:focus {
  outline: none;
}

.vat-hover-highlight:hover {
  background-color: #f6f6f6;
}

.w150 {
  width: 150px;
  margin-top: 4px;
}

.w160 {
  width: 160px;
  margin-top: 4px;
}

.w80 {
  width: 60px;
  margin-top: 2px;
  margin-left: 2px;
}

.samme {
  display: inline-block;
}

.w180 {
  width: 150px;
  margin-top: 4px;
}

.w150test {
  width: 150px;
  margin-top: 4px;
  margin-bottom: 4px;
}

.hh {
  display: inline-block;
  margin-left: 4px;
}

.h0 {
  margin-bottom: 0;
  margin-top: 0;
  background-color: white;
}

.layerComponent {
  background-color: white;
  border-color: darkgray;
  border-style: solid;
  border-width: 1px;
  padding: 4px;
  margin-left:4px;
}

.layerComponent2 {
  background-color: white;
  border-color: darkgray;
  border-style: solid;
  border-width: 1px;
  padding: 4px;
}

.legendbox {
  background-color: white;
  border-color: darkgray;
  border-style: solid;
  border-width: 1px;
  padding: 4px;
  width: 200px;
}

.roed {
  background-color: orange;
}

.btnLag {
  width: 48px;
  height: 48px;
  margin-bottom: 0;
}

.btnLag3 {
  width: 200px;
  height: 48px;
  margin-bottom: 0;
  margin-top: 4px;
}

.btnInfo {
  width: 48px;
  height: 48px;
}

.btnLag2 {
  width: 48px;
  height: 48px;
  margin-bottom: 0;
  margin-top: 4px;
}

.lagreBoks {
  width: 300px;
  height: 280px;
  background-color: white;
  border-color: darkgray;
  border-style: solid;
  border-width: 1px;
  padding: 4px;
  margin: 4px;
}

.leaflet-control-minimap {
  border: solid gray 1px;
  box-shadow: 0;
  border-radius: 0;
  background: #f8f8f9;
  transition: all 0.2s;
}

.leaflet-control-minimap a {
  background-color: rgba(255, 255, 255, 1);
  background-repeat: no-repeat;
  z-index: 99999;
  transition: all 0.2s;
  border-radius: 0;
}

.leaflet-control-minimap-toggle-display {
  background-image: url('./minimap.png');
  height: 40px;
  width: 40px;
  position: absolute;
  bottom: 0;
  right: 0;
}

.leaflet-locate {
  background-image: url('./locate.png');
  position: absolute;
  height: 30px;
  width: 38px;
  bottom: 0;
  right: 0;
}

.leaflet-control-minimap a {
  height: 40px;
  width: 40px;
}

.leaflet-control {
  margin-top: 0 !important;
}

.leaflet-default-icon-path {
  background-image: url(marker-icon.png);
}

.w100 {
  width: 300px;
  margin-top: 2px;
  margin-left: 4px;
  margin-right: 4px;
  height: 42px;
}

.height40 {
  height: 40px;
}

.sted {
  font-size: 11px;
  position: relative;
  top: -8px;
}

.zz {
  z-index: 999999;
}

.left54 {
  margin-left: 40px;
  top: -2px;
}

.speciallink {
  font-weight: bold;
}

.leggtilBtn {
  width: 130px;
  height: 48px;
  padding: 0;
}

.leaflet-div-icon {
  background-color: orangered;
  /*background-image: url('https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png'); */
}

.leaflet-pseudo-fullscreen {
  position: fixed !important;
  width: 100% !important;
  height: 100% !important;
  top: 0px !important;
  left: 0px !important;
  z-index: 99999;
}

.zoomlabel {
  background-color: Gainsboro;
  color: #0078a8;
  font-size: 10px;
}

.w44 {
  width: 44px;
}

.labelred {
  color: darkred;
  margin-left: 10px;
}

.greenline {
  border-top: 1px solid green;
  margin-top: 8px;
  margin-bottom: 6px;
}

.legends {
  background-color: white;
  border: 1px solid gray;
  padding: 6px;
}

/* .marker-cluster-small {
  background-color: rgba(186, 186, 186, 0.6) !important;
  border-color: red;
}
.marker-cluster-small div {
  background-color: rgba(186, 186, 186, 0.6) !important;
  border-color: red;
}

.marker-cluster-medium {
  background-color: rgba(135, 135, 135, 0.6) !important;
  color: white;
  border-color: red;
}
.marker-cluster-medium div {
  background-color: rgba(135, 135, 135, 0.6) !important;
  color: white;
  border-color: red;
}

.marker-cluster-large {
  background-color: rgba(109, 109, 109, 0.6) !important;
  color: white;
  border-color: red;
}
.marker-cluster-large div {
  background-color: rgba(109, 109, 109, 0.6) !important;
  color: white;
  border-color: red;
} */

.leaflet-draw-tooltip {
  background: rgba(161, 196, 56, 0.6);

  border: 1px solid #388a8d;
  color: Black;
  font: 12px/18px 'Helvetica Neue', Arial, Helvetica, sans-serif;
  margin-left: 20px;
  margin-top: -21px;
  padding: 4px 8px;
  position: absolute;
  visibility: hidden;
  white-space: nowrap;
  z-index: 6;
}

.leaflet-poly-icon {
  background: red;
}

.ltooltip {
  background-color: red;
  font-size: 12px;
}

.leaflet-popup-content-wrapper {
  width: 240px;
}

.leaflet-popup-content-wrapper,
.leaflet-popup-content {
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  border-radius: 0 !important;
}

.bold {
  font-weight: bold;
  color: darkgreen;
}

.paa {
  background-color: rgba(161, 196, 56, 0.6);
  border-color: darkgray;
  border-style: solid;
  border-width: 1px;
}

.av {
  background-color: white;
  border-color: darkgray;
  border-style: solid;
  border-width: 1px;
}

.lysNorkartGreen {
  background-color: rgba(161, 196, 56, 1);
}

.panelBox {
  background-color: white;
  border-color: darkgray;
  border-style: solid;
  border-width: 1px;    
}
.fontsvart {
  color: black;
}
.text-labels {
  font-size: 16px;
  font-weight: bold;
  color: darkgreen;
  /*text-decoration: underline;*/
  margin-left: 4px !important;
  margin-top: 2px !important;
  text-shadow: 0 0 0.1em white, 0 0 0.1em white, 0 0 0.1em white,0 0 0.1em white,0 0 0.1em;
}

.font16 {
  font-size: 16px;
}

.fontblue {
  color:blue;
}

.fontblue:hover {
  text-decoration: underline; 
}

.fargeGjRed {
  color: red;
}

.fargeGjGreen {
  color: green;
}

.subPanel {
  margin-left: 2px;
  /*display: inline-block;  */
}

.spretter {
  border-radius: 50%;
  background: fuchsia;
  border-color: black;
  border-width: 2px;
  border-style: solid;
}

.regionerScroll {
  overflow-y: scroll; 
  height: auto;
  max-height: 400px;
  background: white;
  padding: 6px;
}

.w290 {
  width: 290px;
}

.w200 {
  width: 200px;
}

.w100 {
  width: 100px;
}

.w260 {
  width: 260px;
}

.brukfigurer {
  padding: 4px;
  background-color: white;
  border-color: black;
  border-style: solid;
  border-width: 1px;
}

.bslett {
  width: 30;  
}

.bslett:hover {
  width: 30; 
  background-color: red;
  color: white;
}

.w90 {
  width: 94px;
}

.m4 {
  margin-right: 4px;
  height: 26px;
  width: 102px;
  font-size: 15px;
  padding-left: 2px;   
  vertical-align: text-top;
}

.startButton {
  width: 144px;
  height: 40px;
}

.situasjonButton {
  width: 156px;
  height: 40px;
}

.tdStartpunkt {
  background-color: #d9d9d9;
}

.tddiv {
  font-size: 16px;
  padding-left: 2px;
  padding-top: 2px;
}

.leaflet-bar .leaflet-control {
    width: 36px !important;
    height: 36px !important;
}
    
.leaflet-touch .leaflet-bar a {
	width: 30px !important;
	height: 30px !important;
	line-height: 30px;
  }
  
 .topmargin4 {
    margin-top: 4px;
  }

.leaflet-bar .leaflet-control {
  width: 30px !important;
	height: 30px !important;
	line-height: 30px;
  }

  .icon-ruler {
    cursor: pointer !important;
    background-image: url('./locate.png') !important;
  }
   


  /*  
    margin-top: -12px; */

   