.malspan:hover {
    position: relative;
}

.malspanred:hover {
    position: relative;
}

.malspan[aria-label]:hover:after {
     content: attr(aria-label);
     padding: 4px 8px;
     position: absolute;
     left: -20;
     height: auto;
     width: 400px;
     top: 0;
     white-space: wrap;
     z-index: 20;
     background:#deedd3;
     border-color: green;
     border-style: solid;
     border-width: 1px;
}

.malspanred[aria-label]:hover:after {
    content: attr(aria-label);
    padding: 4px 8px;
    position: absolute;
    left: -20;
    height: auto;
    width: 200px;
    top: 0;
    white-space: wrap;
    z-index: 20;
    background:#e9b5b3;
    border-color: darkred;
    border-style: solid;
    border-width: 1px;
}

.malModalHeight {
    height: calc(100vh - 160px);
}

.malModalFooter {
    padding: 10px;
    text-align: right;
    border-top: 1px solid #e5e5e5;
    border-color: green;
    border-width: 1px;
    position: absolute;
    background-color: whitesmoke;
    bottom: 0;
    width: 100%;
    height: 50px;
}

.malModalMediumX {  
    position: absolute;
    top: 20px;
    left: calc(50vw - 360px);
    width: 808px;
    bottom: 20px;
    background: whitesmoke;
    border-style: solid;
    border-color: darkgrey;
    border-width: 4px;
  }

.malrowX {
    padding: 20px;
    background-color: white;
    height: calc(100vh - 280px); 
    overflow-y: auto;
    overflow-x: auto;
}

.text {
    position: relative;
  }
  
  .text-tooltip {
    display: none;
    position: absolute;
    top: 100%;
    width: 750px;
    left: 20px;
    padding: 10px;
    color: black;
    border: 1px solid green;
    background-color: #deedd3;
    -ms-transform: scale(0.6);
    -moz-transform: scale(0.6);
    -o-transform: scale(0.6);
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
    
    -ms-transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -o-transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
  }

  .text:hover .text-tooltip {
    display: block;
  }

  .text-tooltip-sms {
    display: none;
    position: absolute;
    top: 100%;
    width: 320px;
    left: 20px;
    padding: 10px;
    color: black;
    border: 1px solid green;
    background-color: #deedd3;
    -ms-transform: scale(0.7);
    -moz-transform: scale(0.7);
    -o-transform: scale(0.7);
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
    
    -ms-transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -o-transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
  }

  .malModalLiten400 {  
    min-height: 200px;
    max-height: 500px;
    position: absolute;
    top: 60px;
    left: calc(50vw - 360px);
    width: 400px;
    background: white;
    border-style: solid;
    border-color: darkgrey;
    border-width: 4px;
  }
  

  .text:hover .text-tooltip-sms {
    display: block;
  }
