.matrikkelinfo{
    background: white;
    margin-top: 15px;

    border: #ebe6e6;
    border-style: solid;
    border-width: thin;
    background: whitesmoke;
}

#sidebar > h4{
    margin-left: 5px;
    font-weight: bold;
}