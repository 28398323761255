.context-menu {
    width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 14px;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;    
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: 4px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
}

.b-popover.contextMenu__popover .b-dialog {
  padding:0;
  border: none;
  border-radius: 0;
  background: transparent;
  box-shadow: none; 
}

.padding4 {   /* må se på nærmere */
  padding-bottom: 1px;
  padding-top: 1px;
}

.padding2 {
  padding-bottom: 1px;
  padding-top: 1px;
}

.glyphicon-menu-hamburger::before {
  content:none!important;
}

.glyphicon-menu-hamburger {
  background-image:url(./nk_010-meny-A.svg);      
  background-repeat:no-repeat;
  height: 30px; 
  width: 28px;     
}
.bigMac {  
  height: 48px;
  width: 30px;
  background-color: white;
  
}

.dropdown-menu {
  right: 0;
  left: auto;
  padding-left: 1px;
  padding-right: 1px;
  margin-right: 0px;
  z-index: 9999;
}