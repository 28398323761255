
.storbutton-enhet-enhet {
  width: 90%;
  min-height: 48px;
  background: #d9d9d9;
  border-color: #999999;
  float: left;
}

.storbutton-enhet-enhet:focus,
.storbutton-enhet-enhet.focus {
  width: 90%;
  min-height: 48px;
  background: #d9d9d9;
}
.panel-enhet-enhet {
  width: 90%;
}

.panel-enhet-dialogx {
  width: 95%;
  min-height: 48px;
  /*  max-height: 100px; */
  margin-bottom: 4px;
  font-size: 16px;
}
.hoyde34 {
  height: 34px;
}

.margin-left-5 {
  margin-left: 5px;
}
.margin-left-10 {
  margin-left: 10px;
}
.margin-left-15 {
  margin-left: 15px;
}
.margin-left-20 {
  margin-left: 20px;
}
.margin-left-30 {
  margin-left: 30px;
}

.margin-left-10pro {
  margin-left: 10%;
}
.margin-right-5 {
  margin-right: 5px;
}
.margin-right-10 {
  margin-right: 10px;
}
.margin-right-15 {
  margin-right: 15px;
}
.margin-right-30 {
  margin-right: 30px;
}
.margin-right-55 {
  margin-right: 55px;
}
.margin-top-5 {
  margin-top: 5px;
}
.margin-top-2 {
  margin-top: 2px;
}
.margin-top-3 {
  margin-top: 3px;
}
.margin-top-10 {
  margin-top: 10px;
}
.margin-top-8 {
  margin-top: 8px;
}
.margin-top-15 {
  margin-top: 15px;
}
.margin-top-25 {
  margin-top: 25px;
}
.margin-bottom-4 {
  margin-bottom: 4px;
}
.select {
  height: 26px;
  line-height: 26p;
}

.height48 {
  height: 48px;
}
.width10 {
  display: inline-block;
  width: 10px;
}

.width50 {
  width: 50px;
}

.width100 {
  width: 100px;
}

.width120 {
  width: 110px;
}

.width130 {
  width: 130px;
}
.width170 {
  width: 170px;
}
.width120Heigth32 {
  width: 120px;
  height: 32px;
}
.viskart {
  height: 24px;
  vertical-align: middle;
}


.situasjon-knapp {
  margin-left: 5px;
  margin-top: 4px;
}

.minwidth216 {
  min-width: 216px;
}

.red {
  color: red;
}
.font-14 {
  font-size: 14px;
}
.grey {
  color: lightslategray;
}
.green {
  color: green;
}
.royk-situasjon-enhet {
  height: 36px;
  border-style: solid;
  border-width: 1px;
  background: #f2f2f2;
}
.ok-knapp {
  width: 77px;
  height: 48px;
}
.endreRisikoText {
  margin-left: 8px;
  margin-top: 4px;
}

.table100 {
  width: 100%;
  table-layout: fixed;
  white-space: pre-line;
  text-align: left;
}

input[type='radio'] {
  /* -ms-transform: scale(2); */ /* IE 9 */
  /* -webkit-transform: scale(2); */ /* Chrome, Safari, Opera */
  /* transform: scale(2); */
}

.pad32 {
  padding: 32px;
}

.tdSpace0 {
  padding-left: 5px;
  padding-right: 5px;
  width: 140px;
}

.tdSpace {
  padding-right: 5px;
}

.tdSpace1 {
  padding-right: 5px;
  min-width: 300px;
}

.marginTekst {
  margin-left: 5px;
  margin-top: 7px;
}

.tdSpace2 {
  height: 20px;
  width: 1px;
}

.tdSpace20 {
  width: 30px;
  padding-right: 5px;
  vertical-align: top;
}

.tdSpace60{
  width: 90px;
  padding-right: 5px;
  vertical-align: top;
}

.tdSpace99{
  max-width: 99%;
  padding-right: 5px;
  vertical-align: top;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;  
}

.collapsPanel {
  /* max-height: 300px; */
  width: 100%;
}

.arrow-toggle .icon-arrow-down,
.arrow-toggle.collapsed .icon-arrow-up {
  display: inline-block;
}
.arrow-toggle.collapsed .icon-arrow-down,
.arrow-toggle .icon-arrow-up {
  display: none;
}

.pointer {
  cursor: pointer;
}

.minHeight400Scroll {
  min-height: 400px;
  overflow-y: auto;
  min-width: 600px;
}

.maxHeight400Scroll {
  max-height: 500px;
  overflow-y: auto;
  min-width: 600px;
}

.right8 {
  right: 8px;
}

.font16 {
  font-size: 16px;
}

.ellipsed {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: calc(100% - 100px); 
  text-align: left;
  display: inline-block;
} 

.sit_button_propil {
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  width: 60px;
  height: 40px;
  line-height: 40px;
}

.situasjonpaneltop {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 0;
  text-align: left;
}

/* min-height: 52px; */
.fluidpadding {
  padding-right: 0px;
  padding-left: 4px;
  max-height: 42px;
  
}

.pro100 {
  width: 100%;
}

.pro90 {
  width: 84%;
}

.pro90min  {
  width: calc(100% - 54px);
}

.hr10 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.btn-default.collapsed .fa-chevron-up,
.btn-default .fa-chevron-down {
  display: none;
}

.btn-default.collapsed .fa-chevron-up,
.btn-default .fa-chevron-down {
  display: inline-block;
}

i.fa {
  cursor: pointer;
  margin-right: 5px;
}

.inline {
  display: inline-block;
}

.menubutton {
  border: none;
  background: transparent;
}

.alignbottom {
  text-align: center;
  vertical-align: bottom;
}

.nowrap {
  white-space: nowrap;
}

.height40 {
  min-height: 40px;
}

.width40 {
  width: 40px;
}

.situasjonfarge {
  background: #d9d9d9;
  border-color: #999999;
}

.paddingroyklop {
  padding-left: 30px;
}

.paddingildsted {
  padding-left: 30px;
}

.bordertop {
  border-top-color: gray;
  border-top-width: 1px;
  border-top-style: solid;
}

.flexcontainer {
  display: inline-flex;
}

.flexitem {
  
}

.sbpilpanel .chevron:after {
  content: "\f077";
}

.sbpilpanel.collapsed .chevron:after {
  content: "\f078";
}

