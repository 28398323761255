.enhetPopup .header {
    font-weight: bold;
}

.enhetPopup .risiko {
    color: darkred;
    font-weight: bold;
}

.enhetPopup {
    text-align: center;
    min-width: 200px;
    
}

.leaflet-popup {
    margin-bottom: 50px; 
    
}
.w100 {
    width: 100px;
    height: 30px !important;
   
}

.opp50 {
    text-align: center;
}
/* .leaflet-popup-content-wrapper {
  margin-bottom: 50px;
}
.leaflet-popup-tip-container {
    margin-bottom: 50px;
    background: red;
} */

/* 
.leaflet-popup-content-wrapper {
    text-align: center;
    padding-bottom: 4px;
    padding-top: 4px;
    width: 150px;
}

.leaflet-popup-content {
    width: 120px;
}





.opp50 {
    width: 120px;  
} */
