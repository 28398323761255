.fr-box.fr-basic .fr-element.fr-view {
  font-family: 'Arial', Arial;

  color: black !important;
  line-height: 16px !important;
  font-size: 16px !important;
}

.froalaEditor {
  max-height: 'calc(100vh - 600px)' !important;
}

.smseditor {
 /* height: 'calc(100vh - 600px)';
   height: 'calc(100vh - 600px)'; */
   box-sizing: border-box;
   max-width: 100%;  
}

strong{
  font-weight:bold;
 }


.imgEx {
  opacity: 0.3;
  margin-left: 40px;
}

.scaleEx {
  -ms-transform: scale(0.9);
  -moz-transform: scale(0.9);
  -o-transform: scale(0.9);
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
  
  -ms-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

