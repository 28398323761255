.malspan:hover {
    position: relative;
}

.malspanred:hover {
    position: relative;
}

.malspan[aria-label]:hover:after {
     content: attr(aria-label);
     padding: 4px 8px;
     position: absolute;
     left: -20;
     height: auto;
     width: 200px;
     top: 0;
     white-space: wrap;
     z-index: 20;
     background:#deedd3;
     border-color: green;
     border-style: solid;
     border-width: 1px;
}

.malspanred[aria-label]:hover:after {
    content: attr(aria-label);
    padding: 4px 8px;
    position: absolute;
    left: -20;
    height: auto;
    width: 200px;
    top: 0;
    white-space: wrap;
    z-index: 20;
    background:#e9b5b3;
    border-color: darkred;
    border-style: solid;
    border-width: 1px;
}

.malModalHeight {
    height: calc(100vh - 160px);
}

.malModalFooter {
    padding: 10px;
    text-align: right;
    border-top: 1px solid #e5e5e5;
    border-color: green;
    border-width: 1px;
    position: absolute;
    background-color: whitesmoke;
    bottom: 0;
    width: 100%;
    height: 50px;
}

.malModalMedium {
    position: absolute;
    top: 60px;
    left: calc(50vw - 360px);
    width: 720px;
    bottom: 60px;
    background: whitesmoke;
    border-style: solid;
    border-color: darkgrey;
    border-width: 4px;
  }

.malrow {
    padding: 20px;
    background-color: white;
    height: calc(100vh - 300px);
    overflow-y: auto;
    overflow-x: auto;
}

/* Tooltip container */
.tooltipx {
    position: relative;
    display: inline-block;
  }
  
  /* Tooltip text */
  .tooltipx .tooltiptextx {
    visibility: hidden;
    width: 250px;
    background-color: black;
    color: #fff;
    text-align: left;
    font-size: 12px;
    padding: 5px;
    border-radius: 6px;
   
     /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -100px;
  }
  
  /* Show the tooltip text when you mouse over the tooltip container */
  .tooltipx:hover .tooltiptextx {
    visibility: visible;
  }
