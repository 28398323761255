@import url(
  //fonts.googleapis.com/css?family=Roboto:400,
  300,
  300italic,
  400italic,
  700,
  700italic&subset=latin,
  vietnamese,
  latin-ext,
  cyrillic,
  cyrillic-ext,
  greek-ext,
  greek
);

@import url(
  //fonts.googleapis.com/css?family=Oswald:400,
  300,
  700&subset=latin,
  latin-ext
);

@import url(//fonts.googleapis.com/css?family=Montserrat:400, 700);

@import url(
  //fonts.googleapis.com/css?family=Calibri:400,
  700,
  400italic,
  700italic
);

@import url(//fonts.googleapis.com/css?family=Open+Sans);

@import url(//fonts.googleapis.com/css2?family=Handlee);

@import url(
  //fonts.googleapis.com/css?family=Open+Sans+Condensed:300,
  300italic,
  700&subset=latin,
  greek,
  greek-ext,
  vietnamese,
  cyrillic-ext,
  cyrillic,
  latin-ext
);

.fr-box.fr-basic .fr-element.fr-view {
  font-family: 'Arial', Arial;
  color: black;
  line-height: 16px !important;
  font-size: 16px !important;
  max-width: 3605px !important;
  margin-left: 30px;
  margin-right: 30px; 
}

.malModalMediumX {
  position: absolute;
  top: 20px;
  left: calc(50vw - 360px);
  width: 808px;
  bottom: 20px;
  background: whitesmoke;
  border-style: solid;
  border-color: darkgrey;
  border-width: 4px;
}

.kontaktScroll {
  overflow-y: scroll;
  height: auto;
  max-height: 580px;
}

.fr-view {
  word-wrap: break-word;
}
.fr-view span[style~='color:'] a {
  color: inherit;
}
.fr-view strong {
  font-weight: 700;
}
.fr-view table {
  page-break-inside: auto !important;
  /*  border: none !important; */
  border-collapse: collapse;
  empty-cells: show;
  max-width: 100%;
}
.fr-view table tr {
  page-break-inside: avoid !important;
  page-break-after: auto !important;
}
.fr-view table td {
  min-width: 5px;
  padding-right: 12px !important;
  padding-top: 6px !important;
}
.fr-view table.fr-dashed-borders td,
.fr-view table.fr-dashed-borders th {
  border-style: dashed;
}
.fr-view table thead {
  page-break-after: avoid !important;
  page-break-inside: avoid !important;
}
.fr-view table tbody {
  page-break-before: avoid !important;
}
.fr-view table.fr-alternate-rows tbody tr:nth-child(2n) {
  background: whitesmoke;
}
.fr-view table th {
  /*  border:none !important; */
  font-weight: bold !important;
  padding-right: 12px !important;
  padding-top: 6px !important;
  page-break-after: avoid !important;
  page-break-before: avoid !important;
  page-break-inside: avoid !important;
}
.fr-view table td,
.fr-view table th {
  /* border:none !important; */
}
.fr-view table td:empty,
.fr-view table th:empty {
  height: 20px;
}
.fr-view table td.fr-highlighted,
.fr-view table th.fr-highlighted {
  border: 2px double green;
}
.fr-view table td.fr-thick,
.fr-view table th.fr-thick {
  border-width: 2px;
}
.fr-view table th {
  background: #ececec;
}
.fr-view table caption {
  font-weight: bold;
  font-size: 18px;
  page-break-inside: avoid !important;
}

/* .fr-quick-insert { display: none; } */

.solidB thead tr th,
.solidB tbody tr td {
  border-style: solid;
  border-width: 2px;
  border-color: darkgray;
}

.stipletB thead tr th,
.stipletB tbody tr td {
  border-style: dashed;
  border-width: 2px;
  border-color: darkgray;
}

.prikketB thead tr th,
.prikketB tbody tr td {
  border-style: dotted;
  border-width: 2px;
  border-color: darkgray;
}

.dobbelB thead tr th,
.dobbelB tbody tr td {
  border: 2px double green;
}

.testB thead tr th,
.testB tbody tr td {
  border: 2px double green;
  color: green;
  font-weight: bolder;
}

.skjultB thead tr th,  
.skjultB tbody tr td {
  border-style: hidden;
  border-width: 2px;
  border-color: darkgray;
  
}

.text {
  position: relative;
}

.text-tooltip {
  display: none;
  position: absolute;
  top: 100%;
  width: 750px;
  left: 20px;
  padding: 10px;
  color: black;
  border: 1px solid green;
  background-color: #deedd3;
  -ms-transform: scale(0.6);
  -moz-transform: scale(0.6);
  -o-transform: scale(0.6);
  -webkit-transform: scale(0.6);
  transform: scale(0.6);
  
  -ms-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  
}

.text:hover .text-tooltip {
  display: block;
  z-index: 99999 !important;
}

#wrapper {
  display: flex;
}






