
.dialogTitle {
    font-size: 16px;
    padding-top: 8px;
}

.closeButton {
    float: right !important;
    padding-top: 0px  !important;
    padding-right: 0px  !important;
    vertical-align: text-top !important;
}

