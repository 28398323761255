body, html {
  height: 100%;
}

/* remove outer padding */
.main .row {
  padding: 0px;
  margin: 0px;
}

/*Remove rounded coners*/
nav.sidebar.navbar {
  border-radius: 0px;
}

nav.sidebar, .main {
  -webkit-transition: margin 200ms ease-out;
  -moz-transition: margin 200ms ease-out;
  -o-transition: margin 200ms ease-out;
  transition: margin 200ms ease-out;
}

/* Add gap to nav and right windows.*/
.main {
  padding: 10px;
  top: 0;
}

/* .....NavBar: Icon only with coloring/layout.....*/
/*small/medium side display*/
@media (min-width: 768px) {
  /*Allow main to be next to Nav*/
  .main {
    position: absolute;
    padding: 0 10px 10px 10px;
    top: 0;
    width: calc(100vw - 70px);
    /*keeps 100% minus nav size*/
    margin-left: 40px;
    float: right;
    background-color: #f6f6f6;
  }
  /*lets nav bar to be showed on mouseover*/
  nav.sidebar:hover + .main {
    margin-left: 200px;
    transition: .4s ease-out .55s;
  }
  /*Center Brand*/
  nav.sidebar.navbar.sidebar > .container .navbar-brand, .navbar > .container-fluid .navbar-brand {
    margin-left: 0px;
  }
  /*Center Brand*/
  nav.sidebar .navbar-brand, nav.sidebar .navbar-header {
    text-align: center;
    width: 100%;
    margin-left: 0px;
  }
  /*Center Icons*/
  nav.sidebar a {
    padding-right: 9px;
  }
  /*adds border top to first nav box */
  nav.sidebar .navbar-nav > li:first-child {
    border-top: 1px #e5e5e5 solid;
  }
  /*adds border to bottom nav boxes*/
  nav.sidebar .navbar-nav > li {
    border-bottom: 1px #e5e5e5 solid;
  }
  /* Colors/style dropdown box*/
  nav.sidebar .navbar-nav .open .dropdown-menu {
    position: static;
    float: none;
    width: auto;
    margin-top: 0;
    background-color: transparent;
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  /*allows nav box to use 100% width*/
  nav.sidebar .navbar-collapse, nav.sidebar .container-fluid {
    padding: 0 0px 0 0px;
  }
  /*colors dropdown box text */
  .navbar-inverse .navbar-nav .open .dropdown-menu > li > a {
    color: #777;
  }
  /*gives sidebar width/height*/
  nav.sidebar {
    width: 200px;
    height: 100%;
    margin-left: -160px;
    float: left;
    z-index: 8000;
    margin-bottom: 0px;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-user-select: none;
  }
  nav.sidebar::-webkit-scrollbar {
    width: 0 !important;
  }
  /*give sidebar 100% width;*/
  nav.sidebar li {
    width: 100%;
  }
  /* Move nav to full on mouse over*/
  nav.sidebar:hover {
    margin-left: 0px;
    transition: .4s ease .55s;
  }
  /*for hiden things when navbar hidden*/
  .forAnimate {
    opacity: 0;
  }
}

/* .....NavBar: Fully showing nav bar..... */
nav.sidebar .navbar-nav .open .dropdown-menu > li > a:hover, nav.sidebar .navbar-nav .open .dropdown-menu > li > a:focus {
  color: #CCC;
  background-color: transparent;
}

nav:hover .forAnimate {
  opacity: 1;
}

section {
  padding-left: 15px;
}

.hjelp_button {
  background-color: transparent;
  border: none;
  color: white;
  width: 100%;
}

.hjelp_div {
  margin-top: 10px;
  margin-left: 10px;
}

.hjelp_span {
  margin-top: 10px;
  margin-right: 3px;
}
