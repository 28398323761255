@charset "UTF-8";
body {
  padding-bottom: 20px;
  background-color: #f6f6f6;
  font-size: 16px !important;
  /* //Rieni */
}

@media (max-width: 768px) {
  /* navbar height issues on mobile */
  .navbar-fixed-top .navbar-collapse,
  .navbar-fixed-bottom .navbar-collapse {
    max-height: calc(100vh - 50px);
  }
}

/* Set padding to keep content from hitting the edges */
.body-content {
  padding-left: 15px;
  padding-right: 15px;
}

.btn-success {
  background: #51a026;
  color: #ffffff;
}

.btn-success:hover,
.btn-success:focus,
.btn-success:active,
.btn-success.active,
.open > .dropdown-toggle.btn-success {
  background: #a1c438;
}

.btn-success:active,
.btn-success.active {
  background: #51a026;
  box-shadow: none;
}

/* Override the default bootstrap behavior where horizontal description lists 
   will truncate terms that are too long to fit in the left column 
*/
.dl-horizontal dt {
  white-space: normal;
}

/* Set width on the form input elements since they're 100% wide by default */
/*input,  endret av Rieni
select,
textarea {
    max-width: 280px;
}*/
.brannlogo {
  /*color: #a1c438;*/
  color: #f6f6f6;
  font-size: 16px;
  font-weight: bold;
}

#brannlogo:hover {
  /*color: lime;*/
  color: #a1c438;
  text-decoration: none;
}

.storbutton {
  width: 100%;
  min-height: 48px;
  font-size: 16px;
}

.font16 {
  font-size: 16px;
}

.font14 {
  font-size: 14px;
}

.font12 {
  font-size: 12px;
}

.litttopspace {
  margin-top: 10px;
}

.optional-wrapper {
  margin-top: 15px;
  margin-bottom: 20px;
}

.w20 {
  width: 20%;
}

.w80 {
  width: 80%;
}

.norkarthavgronn {
  color: #388a8d;
}

.norkartlysgronn {
  background: #a1c438;
}

.norkartlysgronnLight {
  background: #dae9af;
}

.norkartrodLight {
  background: #f4bebe;
}

.tett {
  margin-top: 0;
  margin-bottom: 20px;
  line-height: 10px;
}

.headermedium {
  color: black;
  font-family: 'Avenir LT Std';
}

.labelright {
  margin-right: 5px;
  display: inline-block;
}

.nowrap {
  white-space: nowrap;
}

.sbpilpanel .chevron:after {
  content: '\f077';
}

.sbpilpanel.collapsed .chevron:after {
  content: '\f078';
}

.form-group.required .control-label:after,
.required-field:after {
  font-family: 'FontAwesome';
  font-weight: lighter;
  font-size: 6px;
  color: gray;
  content: ' \F069';
  vertical-align: super;
}

.soekopp {
  vertical-align: top;
  font-size: 14px;
}

.font-14 {
  font-size: 14px;
}

.viskart {
  height: 24px;
}

.knapsok {
  height: 24px;
}

.width120 {
  width: 110px;
}

.width220 {
  width: 220px;
}

.width350 {
  width: 350px;
}

.width300 {
  width: 300px;
}

.width90 {
  width: 90px;
}

.h26 {
  height: 26px;
}

.h30 {
  height: 30px;
}

.h34 {
  height: 34px;
}

.labelsok {
  width: 74px;
  text-align: left;
  padding-right: 10px;
}

.labelsok120 {
  width: 120px;
  text-align: left;
  padding-right: 10px;
}

.norkart-form .alert {
  margin-top: 0px;
}

button > * {
  vertical-align: middle;
}

.nka-button {
  border: 1px solid #999 !important;
}

.h48 {
  height: 48px;
}

.modal-body {
  position: relative;
  padding: 5px;
}

.horizontallist li {
  display: inline;
  list-style-type: none;
  padding-right: 20px;
}

.panel {
  border: 0;
  box-shadow: none;
}

.paddingtop8 {
  padding-top: 8px;
}

.paddingtop4 {
  padding-top: 4px;
}

.form-control-inline {
  width: auto;
  float: left;
  margin-right: 5px;
}

.form-control {
  padding-right: 4px;
  padding-left: 6px;
  padding-bottom: 6px;
}

.vcenter * {
  vertical-align: middle;
  display: inline-block;
  float: none;
}

.btn i {
  margin-left: 10px;
  font-size: 20px;
}

.marginleft4 {
  margin-left: 4px;
}

.marginleft10 {
  margin-left: 10px;
}

.marginleft30 {
  margin-left: 30px;
}

.norkartgreen {
  color: #51a026;
}

.ulborder {
  border: 1px solid lightgrey;
}

.fotoimage {
  height: 114px;
  width: 152px;
}

.tafotobutton {
  width: 100%;
}

.width148 {
  width: 148px;
}

.width100pro {
  width: 100%;
}

.width50pro {
  width: 50%;
}

.vresize {
  resize: vertical;
}

.fotocontainer {
  position: relative;
  width: 152px;
  height: 150px;
  float: left;
  margin-left: 4px;
  border-width: 2px;
  border-color: gray;
}

.fotocheckbox {
  position: absolute;
  bottom: 0px;
  left: 0px;
}

.fotodelete {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 24px;
  height: 24px;
  color: red;
}

.datepickerStor {
  width: 110px;
  height: 32px;
  margin-left: 4px;
  padding-left: 2px;
}

.react-datepicker__close-icon::after {
  background-color: transparent;
  color: black;
  font-size: 14px;
  font-weight: bold;
  content: '\D7';
  padding: 0px;
}

.react-datepicker {
  font-size: 1.3rem !important;
}

.react-datepicker__current-month {
  font-size: 1.5rem !important;
}

.react-datepicker__header {
  padding-top: 6px !important;
}

.react-datepicker__navigation {
  top: 13px !important;
}

.react-datepicker__day-name,
.react-datepicker__day {
  margin: 0.5rem !important;
}

.form-group.required .control-label:after,
.required-field:after {
  font-family: 'FontAwesome';
  font-weight: lighter;
  font-size: 6px;
  color: gray;
  content: ' \F069';
  vertical-align: super;
}

.sokTop {
  vertical-align: text-top;
}

.nav-pills > .active > a,
.nav-pills > .active > a:hover {
  background-color: #a1c438;
}

.nav-pills > li.active > a,
.nav-pills > li.active > a:hover,
.nav-pills > li.active > a:focus {
  background-color: #a1c438;
}

.nav-pills > li > a {
  background-color: lightgray;
}

.gronn .active a,
.gronn .active a:hover {
  background-color: #a1c438;
}

.hideinput {
  width: 1px;
  height: 1px;
  border-width: 0px;
}

.minheight400 {
  min-height: 400px;
}

.minheight300 {
  min-height: 300px;
}

.gallery {
  width: 100%;
  display: block;
  min-height: 1px;
  border: 1px solid #ddd;
  overflow: auto;
}

.button-arbliste {
  font-size: 15px;
  width: 180px;
}

.button-arbliste-enhet {
  font-size: 15px;
}

.marginright10 {
  margin-right: 10px;
}

.sk-layout__body {
  margin: 0px !important;
}

.sk-layout__label {
  font-size: 12px !important;
}

.sk-layout__top-bar {
  position: static !important;
}

.spanunum {
  font-size: 14px !important;
}

.back-norkart-grey {
  background-color: #dfe0e1;
}

.top-margin-4 {
  margin-top: 4px;
}

.switchLabel {
  margin-left: 8px;
  margin-top: 4px;
}

.samme-linje {
  display: inline-block;
}

/* React-table  */
.react-table input[type='checkbox'] {
  background-size: 20px;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  margin: 0;
}

.react-table .table > tbody + tbody {
  border-top: 2px solid darkgray;
}

.table .table-vertical > tbody {
  border-top-width: 5px;
  border-bottom: 3px solid #bbb;
}

.table .table-vertical > tbody:after {
  content: '';
  display: block;
  height: 5px;
}

.react-table .table-vertical td,
.react-table .table-vertical th {
  border: 0px;
}

.react-table .table tr:hover {
  background-color: #dfe0e1;
  cursor: pointer;
}

.react-table .table-vertical tr:hover {
  background-color: #ffffff;
}

.svartbak {
  background: black;
  color: white;
  font-weight: bold;
  font-family: 'Avenir LT Std';
}

/* Loader */
.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}

.loader {
  color: #1a7e0e;
  font-size: 10px;
  margin: 80px auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.loader:before,
.loader:after {
  content: '';
  position: absolute;
  top: 0;
}

.loader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.loader:after {
  left: 3.5em;
}

@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

.marginleft {
  margin-left: 14px;
}

.missingSkybranndata {
  color: gray;
  font-style: italic;
}

.harSkybranndata {
  color: black;
}

.unummerert {
  text-decoration: line-through;
}

.missingSkybranndata2 {
  color: green;
  font-weight: bold;
}

.padding0 {
  padding: 0 !important;
}

.hideButton {
  margin-left: auto;
  cursor: pointer;
}

.soek-scroller {
  height: calc(100vh - 21vw);
  overflow: auto;
  overflow-x: hidden;
}

.scroller {
  height: calc(100vh - 200px);
  overflow: auto;
  overflow-x: hidden;
}

.scrollerTekster {
  height: 550px;
  overflow: auto;
  overflow-x: hidden;
}

.scrollerMedium {
  height: 60vh;
  overflow: auto;
  overflow-x: hidden;
}

.scrollerMedium80 {
  height: 80vh;
  overflow: auto;
  overflow-x: hidden;
}

.width100px {
  width: 100px;
}

.height24px {
  height: 24px;
}

.height38 {
  height: 38px;
}

.height48 {
  height: 48px;
}

.textareaNoresize {
  resize: none;
}

.glyphicon-refresh-animate {
  -animation: spin 0.7s infinite linear;
  -webkit-animation: spin2 0.7s infinite linear;
  -animation: spin2 0.7s infinite linear;
}

@-webkit-keyframes spin2 {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: scale(1) rotate(0deg);
  }
  to {
    transform: scale(1) rotate(360deg);
  }
}

/*  Ifm Firefox: icons/glyphs på knappen ble plassert for lavt */
.btn {
  white-space: normal;
}

.autoIcon {
  position: absolute;
  top: 3px;
  left: 10px;
  width: 24px;
  height: 24px;
  opacity: 0.3;
}

input:focus::-webkit-input-placeholder {
  opacity: 0;
}

.red {
  color: red;
}

.storcheckbox {
  height: 28px;
  width: 28px;
  vertical-align: middle;
}

.storcheckboxlabel {
  word-wrap: break-word;
  font-weight: normal;
  vertical-align: middle;
}

.checkbox30 {
  width: 28px;
  height: 28px;
}

.checkbox20 {
  width: 20px;
  height: 20px;
}

.checkboxtext18 {
  font-size: 16px;
  margin-left: 10px;
  display: inline;
  vertical-align: super;
  font-weight: normal;
}

.checkboxtext14 {
  font-size: 14px;
  margin-left: 10px;
  display: inline;
  vertical-align: super;
  font-weight: normal;
}

.checkboxtext12 {
  font-size: 12px;
  margin-left: 10px;
  display: inline;
  vertical-align: super;
  font-weight: normal;
}

.checkboxtext16 {
  font-size: 16px;
  margin-left: 10px;
  display: inline-block;
}

.redBackground {
  background: #e25653;
}

.blueBackground {
  background: #87a8e0;
}

.blueBackgroundLight {
  background: #8ec8d1;
  color: navy;
  border-style: double;
  border-color: navy;
  border-width: 2px;
}

.fyringsforbudBackground {
  background: gray;
  color: white;
  border-style: ridge;
  border-color: red;
  border-width: 4px;
}

.marginRight4 {
  margin-right: 4px;
}

.dropdownButtonMenu {
  height: 32px;
  width: 32px;
  padding-left: 4px;
  padding-top: 4px;
}

.dropdowncelle {
  padding-top: 3px;
}

.bilde {
  padding-left: 30px;
  padding-right: 30px;
}

.inline {
  display: inline-block;
}

.idiv {
  padding-left: 16px;
  padding-right: 14px;
}

.sk-hits-stats__info {
  font-size: 12px !important;
  color: black !important;
}

.noBlurry {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.sk-pagination-navigation {
  width: 180px !important;
  min-width: 180px !important;
  margin-left: 5px !important;
}

.sk-action-bar {
  margin-bottom: 0 !important;
}

.sk-action-bar-row {
  margin-bottom: 0 !important;
}

.sk-filter-groups {
  margin-bottom: 0 !important;
}

.sk-results-list {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.sendtRow {
  background-color: #acc198;
  color: darkgreen;
  /*  border-color: #70944C;
  border-width: 2px;
  border-style: solid; */
}

.ellipsed80 {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 80px;
  max-width: 80px;
  text-align: left;
  display: inline-block;
}

.ellipsed90 {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 90px;
  max-width: 90px;
  text-align: left;
  display: inline-block;
}

.ellipsed100 {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100px;
  max-width: 100px;
  text-align: left;
  display: inline-block;
}

.text-tooltipA {
  display: none;
  position: absolute;
  top: 100%;
  width: 500px;
  left: 60px;
  padding: 10px;
  color: black;
  border: 1px solid green;
  background-color: #deedd3;
}

.textA:hover .text-tooltipA {
  display: block;
}

.knappEllipsis {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 4px;
  text-align: left;
}

.modalRekkefolge {  
  position: absolute;
  top: 60px;
  left: calc(10vw);
  width: calc(80vw);
  height: calc(80vh);
  bottom: 60px;
  background: whitesmoke;
  border-style: solid;
  border-color: lightgray;
  border-width: 4px;
}

.prikprikprik1 {
  padding-left: 6px;
  border-color: lightgray;
  border-width: 1px;
  border-style: solid;
  vertical-align: middle;
  min-height: 30px;
   line-height: 24px; 
  float:left;
  background-color: white;
}

.prikprikprik2 {
  margin-left: 4px;
  float:left;
}


