
.malModalSvarut {  
  position: absolute;
  top: 40px;
  left: calc(50vw - 360px);
  width: 800px;
  bottom: 40px;
  background: whitesmoke;
  border-style: solid;
  border-color: darkgrey;
  border-width: 4px;
}

cont {
  width: '100%';
}


.text {
  position: relative;
}

.text-tooltip-SU {
  display: none;
  position: absolute;
  top: 100%;
  width: 400px;
  right: 0px;
  padding: 10px;
  color: black;
  border: 1px solid darkred;
  background-color: #f0d1d1;   
}

.text:hover .text-tooltip-SU {
  display: block;
}





