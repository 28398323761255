.margin-left-10 {
    margin-left: 10px;
}

.margin-left-15 {
    margin-left: 15px;
}

.margin-right-15 {
    margin-right: 15px;
}

.lukketText {
    margin-left: 8px;
    margin-top: 4px;   
}

.margin-right-10 {
    margin-right: 10px;  
}

.hr {
    border: rgb(14, 53, 124);
    height: 3px;
    /* Set the hr color */
    color: rgb(14, 53, 124);; /* old IE */
    background-color:  rgb(14, 53, 124); /* Modern Browsers */
}

.hr1 {
    border: rgb(14, 53, 124);
    height: 1px;
    /* Set the hr color */
    color: rgb(14, 53, 124);; /* old IE */
    background-color:  rgb(14, 53, 124); /* Modern Browsers */
}
